import { render, staticRenderFns } from "./TheWhatThey2.vue?vue&type=template&id=e813a776&scoped=true&"
import script from "./TheWhatThey2.vue?vue&type=script&lang=js&"
export * from "./TheWhatThey2.vue?vue&type=script&lang=js&"
import style0 from "./TheWhatThey2.vue?vue&type=style&index=0&id=e813a776&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e813a776",
  null
  
)

export default component.exports