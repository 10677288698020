// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/logo_x2.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".b-signup[data-v-403f1ea7]{text-align:center}.b-signup__header[data-v-403f1ea7]{padding:4.4rem 5.9rem}.b-signup__container[data-v-403f1ea7]{width:38rem;margin:-8rem auto 0;text-align:center}@media only screen and (max-width:700px){.b-signup__container[data-v-403f1ea7]{padding:0 2rem}}.b-signup__logo[data-v-403f1ea7]{width:15.2rem;height:15.4rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:cover;margin:0 auto 7.4rem}@media only screen and (max-width:700px){.b-signup__logo[data-v-403f1ea7]{margin:5rem auto}}.b-signup .agreement[data-v-403f1ea7]{font-size:1.4rem;line-height:1.7rem;margin:0 -3rem}.b-signup h2[data-v-403f1ea7]{font-size:2.4rem;font-family:Montserrat Alternates,Helvetica,Arial,sans-serif;font-weight:500;line-height:1.2;text-transform:uppercase;text-transform:none}@media only screen and (max-width:980px){.b-signup h2[data-v-403f1ea7]{font-size:2rem}}@media only screen and (max-width:768px){.b-signup h2[data-v-403f1ea7]{font-size:2rem}}.b-signup__pdf[data-v-403f1ea7]{display:flex;flex-direction:column;align-items:center}.b-signup__pdf svg[data-v-403f1ea7]{margin-bottom:1.52rem}", ""]);
// Exports
module.exports = exports;
