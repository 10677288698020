<template>
  <div class="b-container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TheContainer'
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'

  .b-container
    width: 100%
    min-width: $mobile
</style>
