<template>
  <div id="app">
    <transition name="fade-down" mode="out-in" appear>
      <router-view />
    </transition>
    <go-top
      :size="50"
      :right="20"
      :bg-color="'#7754FF'"
    />
  </div>
</template>

<script>
import GoTop from '@inotom/vue-go-top'

export default {
  components: {
    GoTop
  }
}
</script>

<style lang="sass">
  @import 'assets/sass/app'
  @keyframes fadeInDown
    from
        opacity: 0.05
        transform: translate3d(0, -60px, 0)
    to
        opacity: 1
        transform: none

  @keyframes fadeOutUp
      from
          opacity: 1
          transform: none
      to
          opacity: 0.05
          transform: translate3d(0, -60px, 0)
  .fade-down-enter-active
      animation-name: fadeInDown
      animation-duration: 0.5s
  .fade-down-leave-active
      animation-name: fadeOutUp
      animation-duration: 0.3s
</style>
