<template>
  <the-block>
    <div class="b-steps">
      <div class="b-chapter" v-if="chapter !== ''">{{ chapter }}</div>
      <div class="b-list">
        <div class="b-list__item" v-for="(item, index) in list" :key="index">
          <div class="b-list__item-icon">
            <img :src="require(`../../assets/images/${item.icon}`)" :alt="item.chapter" />
            <span class="b-arrow"/>
          </div>
          <div class="b-list__item-content">
            <div class="b-list__item-chapter" v-html="item.chapter"></div>
            <div class="b-list__item-text" v-html="item.text"></div>
          </div>
        </div>
      </div>
    </div>
  </the-block>
</template>

<script>
const LIST = [
  {
    icon: 'step1.svg',
    chapter: 'Шаг 1',
    text: 'Зарегистрируйтесь! <br>Это бесплатно.'
  },
  {
    icon: 'step2.svg',
    chapter: 'Шаг 2',
    text: 'Подключите Zoom, чтобы <br>использовать все <br>возможности платформы (рекомендуем).'
  },
  {
    icon: 'step3.svg',
    chapter: 'Шаг 3',
    text: 'Привяжите вашу карту, <br>на которую будут <br>приходить платежи <br>за занятия и курсы. '
  },
  {
    icon: 'step4.svg',
    chapter: 'Шаг 4',
    text: 'Отметьте удобное <br>для работы время <br>в расписании.'
  },
  {
    icon: 'step5.svg',
    chapter: 'Шаг 5',
    text: 'Укажите стоимость <br>занятий.'
  },
  {
    icon: 'step6.svg',
    chapter: 'Шаг 6',
    text: 'Проводите занятия, <br>которые уже были <br>оплачены студентами.'
  },
  {
    icon: 'step7.svg',
    chapter: 'Шаг 7',
    text: 'Создавайте любое <br>количество курсов <br>и тестов. Продавайте их <br>или используйте <br>во время занятий.'
  }
]

export default {
  name: 'TheSteps',
  props: {
    chapter: {
      default: 'Начать работать — просто!',
      type: String
    },
    list: {
      default: () => LIST,
      type: Array
    }
  }
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .b-steps
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    margin: 7rem auto 0
  .b-chapter
    @include h1
    text-align: center
    font-weight: 400
    padding: 0 0 1.6rem
  .b-text
    @include p
  .b-list
    width: 100%
    max-width: 105rem
    display: flex
    flex-wrap: wrap

    padding: 6.4rem 0 5rem 4.5%
    &__item
      $this: &
      width: 25%
      min-height: 11rem
      margin: 0 0 5rem 0
      padding: 0 6rem 0 0

      position: relative
      display: flex
      flex-direction: column

      transition: all .5s
      @media only screen and (max-width: $mini)
        width: 50%
      @media only screen and (max-width: $micro)
        width: 100%
      &-content
        order: 1
      &-icon
        margin: 0 0 1.8rem
        height: 5rem
        transition: all .5s

        display: flex
        align-items: center
        width: 100%
        & img
          width: 5rem
        & .b-arrow
          margin: 0 0 0 1rem
          width: 17.5rem
          min-width: 17.5rem
          height: 1.6rem
          background-image: url(../../assets/images/arrow.svg)
          background-repeat: no-repeat
          background-position: center
          background-size: contain
          #{$this}:nth-child(4n + 4) &,
          #{$this}:last-child &
            display: none
          @media only screen and (max-width: $mini)
            #{$this}:nth-child(4n + 4) &
              display: block
            #{$this}:last-child &
              display: none
      &-chapter
        @include p
        font-weight: 500
        text-transform: uppercase
        padding-bottom: .8rem
      &-text
        @include p
        font-size: 1.8rem
        padding: 0 2.4rem 2.4rem 0
      &:nth-child(4n + 4)
        width: 20rem
        margin: 0
        padding: 0
        @media only screen and (max-width: $mini)
          width: 50%
        @media only screen and (max-width: $micro)
          width: 100%
</style>
