<template>
  <div class="b-footer-menu">
    <div>
    <template v-for="link in links">
      <a :key="link.name" :href="link.url" class="b-link">
        {{ link.text }}
      </a>
    </template>
    </div>
    <div>
      <a href="mailto:info@4smart.pro">info@4smart.pro</a>
    </div>
  </div>
</template>

<script>
import VueScrollTo from 'vue-scrollto'

const LINKS = [
  {
    name: 'features',
    text: 'Возможности',
    url: '/#features'
  },
  {
    name: 'whom',
    text: 'Решения',
    url: '/#whom'
  },
  {
    name: 'tariffs',
    text: 'Тарифы',
    url: '/#tariffs'
  }
]

export default {
  name: 'TheFooterMenu',

  data () {
    return {
      links: LINKS
    }
  },

  methods: {
    scrollTo (id) {
      let options = {
        container: 'body',
        duration: 500,
        easing: 'ease',
        offset: -80,
        force: true,
        cancelable: true,
        onStart: false,
        onDone: false,
        onCancel: false,
        x: false,
        y: true
      }
      VueScrollTo.scrollTo(`#${id}`, 500, options)
    }
  }
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .b-footer-menu
    width: 100%
    padding: 0 0 4.4rem
    margin: 0 0 2.5rem
    border-bottom: 1px solid rgba($white, .2)

    display: flex
    justify-content: space-between
    flex-wrap: wrap
    & > div
      display: flex
      a
        color: $white
        text-decoration: none
  .b-link
    display: block
    font-size: 1.8rem
    color: $white
    text-decoration: none
    margin: 0 2.4rem 2.4rem 0
    cursor: pointer
</style>
