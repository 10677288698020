// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/logo.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".b-header[data-v-ac3c5fc6]{width:100%;max-width:1440px;display:flex;justify-content:space-between;align-items:center;flex-direction:row}.b-logo[data-v-ac3c5fc6]{display:block;width:14.9rem;height:2.525rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:cover;text-indent:-9999px}@media only screen and (max-width:700px){.b-logo[data-v-ac3c5fc6]{margin:2rem 0}}", ""]);
// Exports
module.exports = exports;
