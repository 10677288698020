<template>
  <div class="l-advantages">
    <the-block>
      <div class="b-advantages">
        <div class="b-chapter">
          {{ chapter }}
        </div>
        <div class="b-list">
          <div class="b-list__item" v-for="(item, index) in list" :key="index">
            <div class="b-list__item-chapter" v-html="item.chapter"></div>
            <div class="b-list__item-text" v-html="item.text"></div>
          </div>
        </div>
      </div>
    </the-block>
  </div>
</template>

<script>
const LIST = [
  {
    chapter: 'до 70%',
    text: 'комиссии от стоимости урока удерживают крупные <br>онлайн-школы'
  },
  {
    chapter: '5',
    text: 'приложений минимум нужно <br>для проведения 1 урока'
  },
  {
    chapter: '~45 мин',
    text: 'в среднем занимает у учителя <br>подготовка к 1 уроку'
  },
  {
    chapter: 'до 50%',
    text: 'новых учеников прекращают <br>обучение в первые 3 месяца'
  }
]
export default {
  name: 'TheAdvantages',
  props: {
    chapter: {
      default: 'Почему нужен 4smart.pro?',
      type: String
    },
    list: {
      default: () => LIST,
      type: Array
    }
  }
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .l-advantages
    width: 100%
    background-color: $violet
  .b-advantages
    margin: 4.9rem
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    @media only screen and (max-width: $micro)
      align-items: flex-start
      margin: 4.9rem 2rem
  .b-chapter
    @include h2
    color: $white
    margin: 0 0 5.8rem
  .b-list
    display: flex
    justify-content: center
    flex-wrap: wrap
    &__item
      width: 25%
      padding: 0 2.8rem
      color: $white
      text-align: center
      @media only screen and (max-width: $mini)
        width: 50%
        margin: 2.8rem 0
      @media only screen and (max-width: $micro)
        width: 100%
        text-align: left
        padding: 0
      &-chapter
        @include h1
        font-weight: 400
      &-text
        margin: .5rem 0 0
</style>
