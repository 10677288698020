// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:wght@400,500&&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Rubik:wght@400&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat+Alternates:wght@400,500,700&&display=swap);"]);
// Module
exports.push([module.id, "*,:after,:before{box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}html{font-size:10px!important}@media only screen and (max-width:1100px){html{font-size:8px!important}}@media only screen and (max-width:768px){html{font-size:10px!important}}body{padding:0;margin:0;overflow-x:hidden;font-family:IBM Plex Sans,Helvetica,Arial,sans-serif;font-size:1.6rem;font-weight:400;line-height:1.2}@media only screen and (max-width:980px){body{font-size:1.4rem}}ul{margin:0;padding:0}ul li{list-style:none;padding:0 0 0 2.8rem;position:relative;line-height:1.8}ul li:before{content:\"\";position:absolute;top:1.2rem;left:1rem;width:.4rem;height:.4rem;border-radius:50%;background-color:#343941}@-webkit-keyframes fadeInDown{0%{opacity:.05;transform:translate3d(0,-60px,0)}to{opacity:1;transform:none}}@keyframes fadeInDown{0%{opacity:.05;transform:translate3d(0,-60px,0)}to{opacity:1;transform:none}}@-webkit-keyframes fadeOutUp{0%{opacity:1;transform:none}to{opacity:.05;transform:translate3d(0,-60px,0)}}@keyframes fadeOutUp{0%{opacity:1;transform:none}to{opacity:.05;transform:translate3d(0,-60px,0)}}.fade-down-enter-active{-webkit-animation-name:fadeInDown;animation-name:fadeInDown;-webkit-animation-duration:.5s;animation-duration:.5s}.fade-down-leave-active{-webkit-animation-name:fadeOutUp;animation-name:fadeOutUp;-webkit-animation-duration:.3s;animation-duration:.3s}", ""]);
// Exports
module.exports = exports;
