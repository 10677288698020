<template>
  <div class="base-form-row" :class="align">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BaseFormRow',

  props: {
    align: {
      default: 'left'
    }
  }
}
</script>

<style lang="sass" scoped>
  .base-form-row
    padding-bottom: 1.8rem

    &.left
      text-align: left
    &.center
      text-align: center
    &.right
      text-align: right
</style>
