<template>
  <div id="main" class="b-for-tutors">
    <the-container>
      <the-main2
        chapter="Начните <br>успешный <br>онлайн-бизнес"
        text="Планируйте свое время и доход, проводите  сессии и консультации, создавайте курсы для большего количества людей с меньшими трудозатратами."
        background="bg-b1-2.png"
      />
    </the-container>
    <the-container>
      <the-what-they2 />
    </the-container>
    <the-container>
      <the-what-they
        chapter="Что получают эксперты?"
        :list="whatThey"
      />
    </the-container>
    <the-container>
      <the-features :list="features" />
    </the-container>
    <the-container>
      <the-solutions :isToggle="true" />
    </the-container>
    <the-container>
      <the-intro
        class="b-intro-tutors"
        chapter="Мы хотим, чтобы вы процветали, поэтому дарим вам гид “8 простых шагов к созданию увлекательного обучающего курса”. Скачивайте и зарабатывайте на своих знаниях!"
        background="bg-intro2.png"
        btnText="Скачать"
      />
    </the-container>
    <the-container>
      <the-end chapter="Превосходный <br>клиентский опыт с <b>4smart.pro!</b>" />
    </the-container>
    <the-container>
      <the-footer>
        <the-footer-menu />
      </the-footer>
    </the-container>
  </div>
</template>

<script>
const ADVANTAGES = [
  {
    chapter: '~25 часов',
    text: 'расходы онлайн-школы <br>на администрирование'
  },
  {
    chapter: '~30 000 ₽',
    text: 'стоимость  программноего обеспечения для обучения'
  },
  {
    chapter: '5',
    text: 'приложений минимум нужно <br>для проведения 1 урока'
  },
  {
    chapter: 'до 50%',
    text: 'новых учеников прекращают <br>обучение в первые 3 месяца'
  }
]
const WHAT_THEY = [
  {
    chapter: 'Забота о клиентах',
    text: 'На нашей платформе есть все для обучения и для обеспечения первоклассного сервиса.',
    icon: 'what4.svg'
  },
  {
    chapter: 'Управление временем',
    text: 'Интерактивное расписание и умная система оповещений освободят ваше время для самого главного — обучения.',
    icon: 'what2.svg'
  },
  {
    chapter: 'Управление доходом',
    text: 'Создайте витрину своих сессий и курсов, позвольте клиентам заранее оплачивать ваши услуги. ',
    icon: 'what1.svg'
  },
  {
    chapter: 'Первоклассный контент',
    text: 'Проводите сессии, записывайте их, объединяйте в курс и продавайте или создавайте авторские курсы для своей аудитории.',
    icon: 'what3.svg'
  }
]
const FEATURES = [
  {
    icon: 'features6.svg',
    chapter: 'Забота о клиентах',
    text: 'Система оповещений, интуитивный интерфейс с подсказками, доступ к материалам 24/7 с любого устройства обеспечат превосходный клиентский опыт.',
    url: '#',
    btnText: 'Начать'
  },
  {
    icon: 'features1.svg',
    chapter: 'Управление доходом',
    text: 'Продавайте пакеты из нескольких сессий или курсы клиентам. Обеспечьте себе гарантии получения средств, даже при поздней отмене.',
    url: '#',
    btnText: 'Начать'
  },
  {
    icon: 'features2.svg',
    chapter: 'Управление временем',
    text: 'Укажите удобное для работы время. Ваши клиенты смогут самостоятельно бронировать, отменять или переносить сессии на свободное время. ',
    url: '#',
    btnText: 'Начать'
  },
  {
    icon: 'features5.svg',
    chapter: 'Первоклассный контент',
    text: 'Создавайте курсы за считанные минуты. С помощью простого перетаскивания добавляйте материалы и задания из готовых шаблонов. ',
    url: '#',
    btnText: 'Начать'
  }
]

export default {
  name: 'ForExperts',

  data () {
    return {
      features: FEATURES,
      advantages: ADVANTAGES,
      whatThey: WHAT_THEY
    }
  }
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .b-for-tutors
    overflow: hidden
  .b-intro-tutors
    /deep/
      .b-intro
        .b-chapter
          @include h3
          @media only screen and (max-width: $micro)
            font-size: 1.6rem
</style>
