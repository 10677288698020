import axios from 'axios'
import api from './api'
import { setCookie, getCookie } from '../utils'

export default {
  state: {
    isAuth: false,
    user: null,
    tariff: null,
    daysToNextPayment: null,
    redirectUrl: false
  },

  mutations: {
    setAuth (state, value) {
      state.isAuth = value
    },

    setUser (state, value) {
      state.user = value
    },

    setToken (state, token) {
      state.access_token = token
    },

    setTariff (state, value) {
      state.tariff = value
    },

    setDaysToNextPayment (state, value) {
      state.daysToNextPayment = value
    },

    setRedirectUrl (state, value) {
      state.redirectUrl = value
    }
  },

  actions: {
    // ============
    //    Sign Up
    // ============

    /**
     * User Sign up
     * @param commit
     * @param dispatch
     * @param data {Object} {name, email, password}
     * @return {Promise.<T>|Promise<any>|Promise}
     */
    signUp ({ commit, dispatch }, data) {
      return axios.post(`${process.env.VUE_APP_API}/auth/signup`, data)
        .then((response) => {
          commit('setUser', response.data)
          dispatch('setToken', response.data)
          return response
        })
        .then(() => {
          dispatch('setUserData', data)
        })
    },

    // ============
    //    Auth
    // ============

    /**
     * User login
     * @param commit
     * @param dispatch
     * @param data {Object} {email, password}
     * @return {Promise.<T>|Promise<any>|Promise}
     */
    login ({ commit, dispatch }, data) {
      return axios.post(`${process.env.VUE_APP_API}/auth/login`, data)
        .then((response) => {
          dispatch('getTariff')

          return response.data
        })
        .then((response) => {
          commit('setUser', response)
          dispatch('setToken', response)
        })
    },

    /**
     * Refresh expired token
     * @param commit
     * @param dispatch
     * @returns {Promise.<T>|Promise<any>|Promise}
     */
    refreshToken ({ dispatch }) {
      return axios.post(`${process.env.VUE_APP_API}/auth/refresh`, {
        refreshToken: getCookie('refreshToken')
      })
        .then((response) => {
          dispatch('setToken', response.data)
          return response.data
        })
        .catch((error) => {
          dispatch('clearAuth')
          return Promise.reject(error)
        })
    },

    /**
     * Logout
     * @param dispatch
     * @returns {Promise.<T>|Promise<any>|Promise}
     */
    logout ({ dispatch }) {
      dispatch('clearAuth')
      return axios.get(`${process.env.VUE_APP_API}/auth/logout`)
    },

    /**
     * Clear cookie & redirect to login
     * @param commit
     * @param dispatch
     */
    clearAuth ({ commit, dispatch }) {
      dispatch('setToken', {
        accessToken: '',
        refreshToken: '',
        clear: true
      })

      commit('setAuth', false)
    },

    /**
     * Set new access token to store and localeStorage
     * @param commit
     * @param token {Object} {accessToken, refreshToken, clear}
     */
    setToken ({ commit }, token) {
      let options = {}

      if (process.env.NODE_ENV === 'production') {
        options = {
          domain: `${process.env.VUE_APP_COOKIE_DOMAIN}`,
          secure: true
        }
      }

      if (token.clear) {
        options['max-age'] = -1
      }

      setCookie('token', token.accessToken, options)
      setCookie('refreshToken', token.refreshToken, options)
      commit('setAuth', true)
    },

    /**
     * Get user info
     * @param commit
     * @returns {userId, mailchimpIntegration, paysuperIntegration }
     */
    getUser ({ commit, dispatch }) {
      return api.request({
        url: `/user`,
        method: 'get'
      })
        .then(data => {
          if (data.name === '') {
            const name = data.email.split('@')
            data.name = name
            dispatch('setUserName', name[0])
              .then(() => {
                commit('setUser', data)
                return data
              })
          } else {
            commit('setUser', data)
            return data
          }
        })
        .then(() => {
          dispatch('getTariff')
        })
    },

    /**
     * Set new user data
     * {
     *  "name": "Vassiliy Poupkine",
     *  "phone": "79123456789",
     *  "country": "Russia",
     *  "city": "Saint-Petersburg",
     *  "busyness": "свободен по утрам",
     *  "subject": "физика"
     * }
     * @param dispatch
     * @param data
     * @return {Promise<AxiosResponse<AxiosResponse<AxiosResponse<T>>>>}
     */
    setUserData ({ dispatch }, data) {
      return axios.post(`${process.env.VUE_APP_API}/user`, data)
        .then((response) => {
          return response
        })
        .then(() => {
          dispatch('getUser')
        })
    }
  },

  namespaced: true
}
