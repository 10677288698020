// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/x.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".b-overlay[data-v-467c857d]{position:fixed;top:0;right:0;bottom:0;left:0;z-index:999;max-width:100vw;max-height:100vh;align-items:center;background-color:rgba(0,0,0,.8);transition:all .5s}.b-content[data-v-467c857d],.b-overlay[data-v-467c857d]{display:flex;justify-content:center}.b-content[data-v-467c857d]{width:100%;height:100%;max-width:70vw;max-height:80vh;position:relative;align-items:flex-start}@media only screen and (min-width:2000px){.b-content[data-v-467c857d]{max-width:144rem}}.b-close[data-v-467c857d]{position:absolute;top:-4.2rem;right:-4.2rem;width:2.8rem;height:2.8rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:cover;cursor:pointer;transition:all .5s}.b-close[data-v-467c857d]:hover{transform:rotate(90deg)}", ""]);
// Exports
module.exports = exports;
