<template>
  <the-block>
    <div class="b-faq">
      <div class="b-chapter">
        Остались вопросы?
      </div>
      <div class="b-faq__item"
        v-for="(item, index) in list"
        :key="index"
        :class="{ '_active': item.active }"
      >
        <div class="b-faq__item-chapter" @click="click(index)">
          <span>{{ item.chapter }}</span>
        </div>
        <div class="b-faq__item-text" v-html="item.text" />
      </div>
    </div>
  </the-block>
</template>

<script>
const LIST = [
  {
    chapter: 'Чем 4smart отличается от других платформ для онлайн-обучения?',
    text: `<p>Во-первых, это БЕСПЛАТНО. Не нужно заранее оплачивать подписку. Мы взимаем только 5% от стоимости оплаченных занятий или курсов. При этом, учитель сам указывает стоимость занятий или курсов.</p>
          <p>Во-вторых, Используя 4smart, учителя и эксперты получают не только многофункциональный виртуальный класс для проведения онлайн-занятий и первоклассный конструктор курсов, но также:</p>
          <ul>
            <li>Личный сайт, на котором могут разместить информацию о себе, о стоимости своих услуг и доступном времени для проведения занятий. Учителя могут делиться ссылкой на личный сайт в своих социальных сетях и в профессиональных сообществах.</li>
            <li>Интерактивное расписание. Студенты и клиенты могут самостоятельно бронировать свободные слоты времени учителя или эксперта, оплачивать занятия, управлять бронированием.</li>
            <li>Витрину товаров и услуг. Учителя и эксперты могут продавать пакеты по 4, 8, 16, 32, 64, 128 занятий, а также курсы.</li>
           </ul>
           <p>И еще много других функций, с которыми точно стоит познакомиться.</p>
           `,
    active: false
  },
  {
    chapter: 'Пользование 4smart бесплатно для моих студентов?',
    text: `<p>Мы взимаем 5% от стоимости оплаченных занятий или курсов за пользование платформой. При этом, учитель сам указывает стоимость занятий или курсов.</p>
          <p>Так, если стоимость вашего занятия - 800 рублей, то после оплаты занятия студентом, вы получите на карту 760 рублей. Если вы укажете стоимость занятия 850 рублей, то получите на карту 807,50 рублей.</p>
          `,
    active: false
  },
  {
    chapter: 'Что, если студенту не понравилось занятие и он хочет вернуть деньги?',
    text: `<p>На нашей платформе вы можете провести 1 пробное занятие с новым студентом. Вы можете указать стоимость занятия со скидкой или бесплатно. Для пробного занятия есть гарантия возврата денег студенту в случае, если вы друг другу не подходите.</p>
          <p>В случае, если студент покупает у вас пакет из 4, 8 и более занятий, то возврат средств в таком случае возможен с удержанием 30% от стоимости баланса. При этом, учитель получит 30% от стоимости баланса минус комиссия за пользование платформой.</p>
          `,
    active: false
  },
  {
    chapter: 'Зачем нужно привязывать банковскую карту?',
    text: `<p>Привяжите ту карту, на которую вы планируете получать поступления от студентов за занятия и курсы. На нее мы будем перечислять вам платежи.</p>`,
    active: false
  },
  {
    chapter: 'Можно ли использовать платформу без привязки банковской карты?',
    text: `<p>Вы можете создавать шаблоны уроков и курсы на платформе без ограничений и необходимости привязки карты, однако, для того, чтобы запланировать урок, вам нужно привязать банковскую карту.</p>
          `,
    active: false
  },
  {
    chapter: 'Будет ли 4smart списывать деньги с моей карты?',
    text: `<p>Нет, 4smart не будет списывать суммы с вашей банковской карты. Вы получаете средства от платформы за проведенное занятие или проданный курс уже за вычетом комиссии в 5% за использование платформы.</p>
          `,
    active: false
  },
  {
    chapter: 'Каким образом я буду получать оплату за занятия?',
    text: `<p>Вы получаете деньги сразу после окончания каждого занятия.</p>
          `,
    active: false
  },
  {
    chapter: 'Что я получу в случае поздней отмены занятия студентом?',
    text: `<p>В случае, если студент отменяет или переносит занятие с учителем позднее, чем за 12 часов до начала занятия, мы списываем с него комиссию в 30% от стоимости занятия. При этом, учитель получит 30% от стоимости занятия минус комиссия за пользование платформой.</p>
          `,
    active: false
  },
  {
    chapter: 'Есть ли ограничения по количеству студентов?',
    text: `<p>Вы можете пользоваться платформой без ограничения по количеству студентов.</p>
          `,
    active: false
  },
  {
    chapter: 'Есть ли ограничения по количеству занятий и курсов?',
    text: `<p>Мы не ограничиваем учителей и экспертов в количестве создаваемых занятий, курсов или размещенных материалов.</p>
          `,
    active: false
  },
  {
    chapter: 'Можно ли использовать платформу с мобильного телефона или планшета?',
    text: `<p>Наша платформа полностью адаптирована под мобильные устройства.</p>
          `,
    active: false
  }
]
export default {
  name: 'TheFaq',

  data () {
    return {
      list: LIST
    }
  },

  methods: {
    click (index) {
      this.list[index].active = !this.list[index].active
    }
  }
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .b-faq
    width: 100%
    max-width: 90rem
    margin: 0 auto 10rem
    text-align: center
    &__item
      $this: &
      text-align: left
      border-radius: .8rem
      padding: 1.6rem 2.2rem
      &-chapter
        font-size: 2rem
        font-weight: 500
        cursor: pointer
        & > span
          border-bottom: 1px dashed $dark-grey
      &-text
        color: $dark-grey
        transition: all 1s
        // display: none
        height: 0
        overflow: hidden
        opacity: 0
      &._active
        background-color: rgba(#53B7FF, .1)
        #{$this}-text
          display: block
          height: auto
          opacity: 1
  .b-chapter
    @include h2
    margin: 0 0 2.4rem
</style>
