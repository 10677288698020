// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/logo.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/check-c.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".b-end[data-v-3207c9b0]{width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center;position:relative;padding:10rem 0 0}.b-logo[data-v-3207c9b0]{position:absolute;left:50%;top:-7rem;width:14rem;height:14rem;margin:0 0 0 -7rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:50%;background-size:contain}.b-chapter[data-v-3207c9b0]{text-align:center;font-family:Montserrat Alternates,Helvetica,Arial,sans-serif;font-size:4.8rem;line-height:1.2;font-weight:700;font-weight:400}@media only screen and (max-width:980px){.b-chapter[data-v-3207c9b0]{font-size:3rem}}@media only screen and (max-width:768px){.b-chapter[data-v-3207c9b0]{font-size:2.4rem}}.b-row[data-v-3207c9b0]{display:flex;justify-content:center;align-items:center;margin:6rem 0}@media only screen and (max-width:980px){.b-row[data-v-3207c9b0]{flex-direction:column}}.b-col[data-v-3207c9b0]{margin:0 0 3rem}.b-text[data-v-3207c9b0]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-repeat:no-repeat;background-position:0;padding:0 0 0 3.4rem;margin:.8rem 0 .8rem 5.2rem}", ""]);
// Exports
module.exports = exports;
