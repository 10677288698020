<template>
  <div id="main" class="b-for-tutors">
    <the-container>
      <the-main2
        chapter="Создайте свою <br>онлайн-школу"
        text="Лучшие инструменты для организации учебного процесса помогут вам создать онлайн-школу с нуля <br>и без вложений. "
        background="bg-b1-2.png"
      />
    </the-container>
    <the-container>
      <the-advantages :list="advantages" />
    </the-container>
    <the-container>
      <the-what-they :list="whatThey" />
    </the-container>
    <the-container>
      <the-features :list="features" />
    </the-container>
    <the-container>
      <the-solutions :isToggle="true" />
    </the-container>
    <the-container>
      <the-intro
        class="b-intro-tutors"
        chapter="Мы хотим, чтобы ваша онлайн-школа процветала, поэтому дарим гид “8 простых шагов к созданию увлекательного обучающего курса”. Скачивайте, создавайте курсы и отстраивайтесь от конкурентов!"
        background="bg-intro2.png"
        btnText="Скачать"
      />
    </the-container>
    <the-container>
      <the-end chapter="Обучайте интересно <br>и без рутины с <b>4smart.pro!</b>" />
    </the-container>
    <the-container>
      <the-footer>
        <the-footer-menu />
      </the-footer>
    </the-container>
  </div>
</template>

<script>
const ADVANTAGES = [
  {
    chapter: '~25&nbsp;часов',
    text: ' в месяц онлайн-школы тратят<br>на администрирование'
  },
  {
    chapter: '~30&nbsp;000&nbsp;₽',
    text: 'в месяц стоимость ПО для обучения'
  },
  {
    chapter: '5',
    text: 'приложений минимум нужно <br>для проведения 1 урока'
  },
  {
    chapter: 'до 50%',
    text: 'новых учеников прекращают <br>обучение в первые 3 месяца'
  }
]
const WHAT_THEY = [
  {
    chapter: 'Управление бронированием',
    text: 'Интерактивное расписание и умная система оповещений заберут часть ваших забот на себя. ',
    icon: 'what4.svg'
  },
  {
    chapter: 'Комфорт учеников',
    text: 'Интуитивный интефейс, все инструменты обучения на одной платформе, удобный обмен материалами и доступ с любого устройства .',
    icon: 'what2.svg'
  },
  {
    chapter: 'Прием оплат',
    text: 'Создайте витрину своих уроков и курсов. Собирайте статистику платежей по времени, ученикам, курсам и влияйте на свой бизнес.',
    icon: 'what1.svg'
  },
  {
    chapter: 'Авторские материалы',
    text: 'Создавайте курсы по своим авторским методикам обучения за считанные минуты.',
    icon: 'what3.svg'
  }
]
const FEATURES = [
  {
    icon: 'features2.svg',
    chapter: 'Управление <br>бронированием',
    text: 'Ваши ученики могут самостоятельно бронировать, отменять или переносить уроки.  Платформа напомнит о начале уроков, оплатах и других событиях. ',
    url: '#',
    btnText: 'Начать'
  },
  {
    icon: 'features1.svg',
    chapter: 'Прием оплат',
    text: 'Продавайте пакеты из нескольких занятий или курсы студентам. Обеспечьте себе гарантии получения средств, даже при поздней отмене.',
    url: '#',
    btnText: 'Начать'
  },
  {
    icon: 'features5.svg',
    chapter: 'Комфорт учеников',
    text: 'Интуитивный интерфейс и подсказки помогут быстро разобраться в работе платформы. Все материалы доступны в личном кабинете 24/7 со всех устройств.',
    url: '#',
    btnText: 'Начать'
  },
  {
    icon: 'features7.svg',
    chapter: 'Авторские <br>материалы',
    text: 'Есть своя методика обучения? Создайте онлайн-курс по ней и продавайте всем желающим. Мы защитим ваши курсы от скачивания.',
    url: '#',
    btnText: 'Начать'
  }
]

export default {
  name: 'ForSchools',

  data () {
    return {
      features: FEATURES,
      advantages: ADVANTAGES,
      whatThey: WHAT_THEY
    }
  }
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .b-for-tutors
    overflow: hidden
  .b-intro-tutors
    /deep/
      .b-intro
        .b-chapter
          @include h3
          @media only screen and (max-width: $micro)
            font-size: 1.6rem
</style>
