<template>
  <div class="b-tariff" id="tariffs">
    <the-block>
      <div class="b-row">
        <div class="b-chapter">
          Единый тариф
        </div>
        <div class="b-percent">
          от стоимости любой совершенной на платформе <br>транзакции: покупка занятий, курсов.
        </div>
        <div class="b-text">
          <div class="b-h3">Что входит:</div>
          <ul>
            <li>Неограниченное проведение занятий</li>
            <li>Добавление неограниченного количества студентов</li>
            <li>Создание неограниченного количества курсов и тестов</li>
            <li>Защита курсов от скачивания</li>
            <li>Интеграция с Zoom</li>
            <li>Прием оплат</li>
            <li>Интерактивное расписание</li>
            <li>Мессенджер</li>
            <li>Умная система оповещений по событиям</li>
          </ul>
        </div>
      </div>
    </the-block>
  </div>
</template>

<script>
export default {
  name: 'TheTariff'
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .b-tariff
    width: 100%
    height: 62.8rem
    background-image: url(../../assets/images/bg-tariff.png)
    background-repeat: no-repeat
    background-position: center
    @media only screen and (max-width: $mini)
      background-position: 50% -50%
      height: auto
      padding-bottom: 10rem
  .b-row
    width: 100%
    height: 62.8rem
    display: flex
    justify-content: center
    align-items: center
    @media only screen and (max-width: $mini)
      flex-direction: column
      height: auto
  .b-chapter
    @include h1
    white-space: nowrap
    text-align: right
    padding: 0 4rem 8rem 0
    @media only screen and (max-width: $mini)
      text-align: center
      padding: 0
  .b-percent
    font-size: 1.4rem
    text-align: center
    min-width: 28.5rem
    min-height: 25.3rem
    height: 62.8rem
    padding: 36rem 0 0
    background-image: url(../../assets/images/5-per.png)
    background-repeat: no-repeat
    background-size: contain
    background-position: center 35%
    @media only screen and (max-width: $mini)
      height: auto
      padding: 26rem 0 0
  .b-text
    padding: 0 0 4rem 4rem
    line-height: 1.8
    @media only screen and (max-width: $mini)
      padding: 2rem 0 0
  .b-h3
    @include h3
    padding: 0 0 1rem 3rem
    @media only screen and (max-width: $mini)
      padding: 0 0 2rem
      text-align: center
</style>
