<template>
  <div id="main" class="b-main">
    <the-container>
      <the-main />
    </the-container>
    <the-container>
      <the-for-whom />
    </the-container>
    <the-container>
      <the-why />
    </the-container>
    <the-container>
      <the-simply-available />
    </the-container>
    <the-container>
      <the-founder />
    </the-container>
    <the-container>
      <the-intro />
    </the-container>
    <the-container>
      <the-features />
    </the-container>
    <the-container>
      <the-solutions />
    </the-container>
    <the-container>
      <the-intro
        chapter="Хотите повысить свой статус <br>и выделяться среди огромного <br>количества репетиторов и экспертов?"
        text="Начните обучать на 4smart"
        background="bg-intro2.png"
      />
    </the-container>
    <the-container>
      <the-steps />
    </the-container>
    <the-container>
      <the-swiper />
    </the-container>
    <the-container>
      <the-tariff />
    </the-container>
    <the-container>
      <the-end />
    </the-container>
    <the-container>
      <the-faq />
    </the-container>
    <the-container>
      <the-footer>
        <the-footer-menu />
      </the-footer>
    </the-container>
  </div>
</template>

<script>
export default {
  name: 'Main'
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .b-main
    overflow: hidden
    background-image: url(../../assets/images/bg.svg)
    background-repeat: no-repeat
    background-position: center 65rem
    @media only screen and (max-width: $medium)
        background-position: center 55rem
    @media only screen and (max-width: $small)
        background-position: center 52rem
    @media only screen and (max-width: $micro)
      min-width: $mobile
      background-position: center 74rem
</style>
