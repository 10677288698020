<template>
  <div class="b-base-error-text">
    <slot></slot>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
  @import '../../../assets/sass/colors'
  @import '../../../assets/sass/_variables.sass'

  .b-base-error-text
    font-size: 1.4rem
    line-height: 1.7rem
    color: $error-red
    margin-top: 0.5rem
</style>
