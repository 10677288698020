// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/bg-b1.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".b-intro[data-v-1fb03fec]{width:100%;position:relative;margin:5rem 0 10rem;min-height:60rem;display:flex;flex-direction:column;justify-content:center;align-items:center;background-repeat:no-repeat;background-position:50%;background-size:contain}@media only screen and (max-width:768px){.b-intro[data-v-1fb03fec]{min-height:30rem;margin:0 0 10rem}}@media only screen and (max-width:700px){.b-intro[data-v-1fb03fec]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")!important}}.b-intro__content[data-v-1fb03fec]{display:flex;flex-direction:column;justify-content:center;align-items:center}.b-intro__bg[data-v-1fb03fec],.b-intro__bg img[data-v-1fb03fec]{width:100%}.b-chapter[data-v-1fb03fec]{text-align:center;font-family:Montserrat Alternates,Helvetica,Arial,sans-serif;font-size:4.8rem;line-height:1.2;font-weight:700;font-size:3.6rem;padding:3rem 10rem}@media only screen and (max-width:768px){.b-chapter[data-v-1fb03fec]{font-size:2.4rem}}@media only screen and (max-width:980px){.b-chapter[data-v-1fb03fec]{font-size:3rem}}@media only screen and (max-width:768px){.b-chapter[data-v-1fb03fec]{font-size:2.4rem;padding:10rem 10rem 3rem}}@media only screen and (max-width:700px){.b-chapter[data-v-1fb03fec]{font-size:1.6rem;padding:3rem}}.b-text[data-v-1fb03fec]{text-transform:uppercase;font-size:2rem;font-weight:400;line-height:1.2}@media only screen and (max-width:980px){.b-text[data-v-1fb03fec]{font-size:1.6rem}}.b-button[data-v-1fb03fec]{margin:1rem 0 0}", ""]);
// Exports
module.exports = exports;
