<template>
  <the-block class="b1 b1-bg" :style="{ 'background-image' : background ? `url(${require(`../../assets/images/${background}`)})` : 'none'}">
    <the-header>
      <the-top-menu />
    </the-header>
    <div class="b1-content">
      <div class="b-main">
        <div class="h1" v-html="chapter"></div>
        <div class="p" v-html="text"></div>
        <the-button color="base" size="middle" @click="$router.push('/signup')">
          {{ btnText }}
        </the-button>
      </div>
      <div class="parallax">
        <div class="b-play" @click="isShowVideo = true"/>
        <div id="parallax">
          <div
            class="b-letters"
            data-depth="1.5"
          />
          <div class="b-laptop" />
          <div
            class="b-letters-back"
            data-depth="0.5"
          />
        </div>
      </div>
      <div class="b-laptop-mobile">
        <div class="b-play" @click="isShowVideo = true"/>
      </div>
    </div>
    <the-overlay v-if="isShowVideo" @close="isShowVideo = false">
      <video class="b-video" autoplay="true" loop="loop">
        <source src="https://cdn.ptah.pro/4smart/4smart.mp4">
      </video>
    </the-overlay>
  </the-block>
</template>

<script>
import Parallax from 'parallax-js'

export default {
  name: 'TheMain',

  props: {
    chapter: {
      default: 'Расширьте свои <br>возможности <br>в преподавании',
      type: String
    },
    text: {
      default: 'Узнайте, как учителю и эксперту обеспечить себе гарантированный доход, создавая незабываемый опыт обучения, и экономить время для себя.',
      type: String
    },
    btnText: {
      default: 'Начать бесплатно',
      type: String
    },
    background: {
      default: 'bg-b1.png',
      type: String
    }
  },

  data () {
    return {
      isShowVideo: false
    }
  },

  mounted () {
    const scene = document.getElementById('parallax')
    const parallaxInstance = new Parallax(scene)

    parallaxInstance.friction(0.2, 0.2)
  }
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .b1
    height: 100%
    &-bg
      background-repeat: no-repeat
      background-size: contain
      background-position: center right
      @media only screen and (max-width: $micro)
        background-position: center 85%
    &-content
      height: 60rem
      position: relative
      @media only screen and (max-width: $medium)
        height: 49rem
      @media only screen and (max-width: $small)
        height: 45rem
      @media only screen and (max-width: $micro)
        height: auto
        margin: 0 0 10rem
  .b-letters-back
    width: 92.7rem
    height: 52.8rem
    background-image: url(../../assets/images/letters-back.png)
    background-repeat: no-repeat
    background-size: contain

    position: absolute
    left: auto !important
    right: -5rem !important
    top: -8rem !important
    z-index: 0
    @media only screen and (max-width: $large)
      width: 86.6rem
      height: 50.6rem
      right: -10rem !important
      top: -20rem !important
    @media only screen and (max-width: $small)
      width: 86.6rem
      height: 50.6rem
      right: -20rem !important
      top: -10rem !important
    @media only screen and (max-width: $mini)
      right: -35rem !important
      top: -10rem !important
    @media only screen and (max-width: $micro)
      right: -20rem !important
      top: 0 !important
  .parallax
    position: absolute
    right: 5rem
    bottom: 0
    @media only screen and (max-width: $large)
      right: -3rem !important
    @media only screen and (max-width: $micro)
      display: none
  .b-letters
    width: 66.6rem
    height: 55.6rem
    background-image: url(../../assets/images/letters.png)
    background-repeat: no-repeat
    background-size: contain

    position: absolute
    left: auto !important
    top: 5rem !important
    right: -20rem !important
    z-index: 2
    @media only screen and (max-width: $large)
      width: 56.6rem
      height: 35.6rem
      top: -8rem !important
      right: -10rem !important
    @media only screen and (max-width: $medium)
      width: 56.6rem
      height: 35.6rem
      top: -4rem !important
      right: -15rem !important
    @media only screen and (max-width: $small)
      top: 0 !important
      right: -20rem !important
    @media only screen and (max-width: $mini)
      width: 36.6rem
      height: 35.6rem
    @media only screen and (max-width: $micro)
      top: 4rem !important
      right: -10rem !important
  .b-laptop
    width: 77rem
    height: 44.6rem
    background-image: url(../../assets/images/laptop.png)
    background-repeat: no-repeat
    background-size: contain
    background-position: bottom

    position: absolute
    top: auto !important
    right: 5rem !important
    bottom: 0
    z-index: 1
    @media only screen and (max-width: $large)
      width: 70.6rem
      height: 40.6rem
      left: auto !important
      right: 0 !important
    @media only screen and (max-width: $medium)
      width: 60.6rem
      height: 36.6rem
      right: 0 !important
    @media only screen and (max-width: $small)
      width: 55.6rem
      height: 40.6rem
      right: -5rem !important
    @media only screen and (max-width: $mini)
      width: 40.6rem
      height: 30.6rem
      right: -5rem !important
      bottom: 10rem
    @media only screen and (max-width: $micro)
      width: 100%
      height: 40rem
      left: 0!important
      right: 0 !important
      background-size: cover
  .b-play
    width: 13rem
    height: 13rem
    margin: -6.5rem 0 0 -6.5rem
    background-image: url(../../assets/images/play.svg)
    background-repeat: no-repeat
    background-size: cover

    position: absolute
    left: 60%
    top: 55%
    cursor: pointer
    opacity: .6
    transition: all .5s
    z-index: 10
    @media only screen and (max-width: $large)
      left: 39%
      top: 33%
    @media only screen and (max-width: $medium)
      width: 10rem
      height: 10rem
      margin: -5rem 0 0 -5rem
      left: 45%
      top: 45%
    @media only screen and (max-width: $small)
      left: 60%
      top: 48%
    @media only screen and (max-width: $mini)
      left: 60%
      top: 35%
    @media only screen and (max-width: $micro)
      width: 13rem
      height: 13rem
      margin: -6.5rem 0 0 -6.5rem
      left: 50%
      top: 45%
    &:hover
      width: 15rem
      height: 15rem
      margin: -7.5rem 0 0 -7.5rem
      opacity: .8
  .b-main
    padding: 6rem 0 0
    max-width: 85rem
    @media only screen and (max-width: $large)
      max-width: 50%
    @media only screen and (max-width: $micro)
      max-width: 100%
      margin: 0 0 10rem
    .h1
      @include h1
    .p
      @include p
      max-width: 53rem
      margin-bottom: 4rem
  .h1
    @include h1
    margin-bottom: 2.4rem
  .b-video
    width: auto
    max-width: 100%
    max-height: 90%
  .b-laptop-mobile
    display: none
    position: relative
    @media only screen and (max-width: $micro)
      display: block
      width: 100%
      height: 22.6rem
      background-image: url(../../assets/images/laptop.png)
      background-repeat: no-repeat
      background-size: contain
      background-position: center
</style>
