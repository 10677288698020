<template>
  <the-block>
    <div class="b-buttons" v-if="toggle">
      <the-button color="base-transparent" size="middle" @click="toggle = false">
        Еще
      </the-button>
    </div>
    <div class="b-solutions" id="solutions" v-if="!toggle">
      <div class="b-chapter" v-if="chapter !== ''">{{ chapter }}</div>
      <div class="b-text" v-if="chapter !== ''">{{ text }}</div>
      <div class="b-list">
        <div class="b-list__item" v-for="(item, index) in list" :key="index">
          <div class="b-list__item-icon">
            <img :src="require(`../../assets/images/${item.icon}`)" :alt="item.chapter" />
          </div>
          <div class="b-list__item-content">
            <div class="b-list__item-chapter" v-html="item.chapter"></div>
            <div class="b-list__item-text">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </the-block>
</template>

<script>
const LIST = [
  {
    icon: 'check.svg',
    chapter: 'Интерактивная доска',
    text: 'Одновременная работа на интерактивной доске делает онлайн-занятие таким же эффективным, как и оффлайн.'
  },
  {
    icon: 'check.svg',
    chapter: 'Конструктор тестов',
    text: 'Проверяйте знания студентов периодически или назначайте домашние задания с помощью конструктора тестов.'
  },
  {
    icon: 'check.svg',
    chapter: 'Поиск в окне урока',
    text: 'Перейти по ссылке в Youtube или на другой ресурс можно прямо в окне занятия, не выходя из программы.'
  },
  {
    icon: 'check.svg',
    chapter: 'Чат и Заметки',
    text: 'Обменивайтесь материалами, отвечайте на вопросы студентов и добавляйте заметки.'
  },
  {
    icon: 'check.svg',
    chapter: 'Удобный доступ',
    text: 'Доступ к занятию - по ссылке. Быстрая регистрация студентов. Подсказки по работе платформы.'
  },
  {
    icon: 'check.svg',
    chapter: 'Безопасность',
    text: 'Мы защитим ваши видео и курсы от скачивания.'
  },
  {
    icon: 'check.svg',
    chapter: 'Облачное решение',
    text: 'Полностью облачное решение. Вам не нужно ничего устанавливать (при условии, что у вас уже есть Zoom).'
  },
  {
    icon: 'check.svg',
    chapter: 'Быстрая загрузка файлов',
    text: 'Перетаскивайте файлы и папки с компьютера или облачных хранилищ на платформу в один клик.'
  },
  {
    icon: 'check.svg',
    chapter: 'Сохранение шаблонов занятий',
    text: 'Удачно подобрали материалы занятий? Сохраняйте их в шаблоны и проводите с другими студентами, экономя время.'
  }
]

export default {
  name: 'TheSolutions',
  props: {
    chapter: {
      default: 'Еще больше возможностей',
      type: String
    },
    text: {
      default: 'Для вашего комфорта и комфорта ваших студентов',
      type: String
    },
    list: {
      default: () => LIST,
      type: Array
    },
    isToggle: {
      default: false,
      type: Boolean
    }
  },

  data () {
    return {
      toggle: false
    }
  },

  mounted () {
    this.toggle = this.isToggle
  }
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .b-solutions
    width: 100%
    display: flex
    flex-direction: column
    align-items: center

    transition: all .5s
  .b-chapter
    @include h1
    font-weight: 400
    padding: 0 0 1.6rem
  .b-text
    @include p
  .b-list
    display: flex
    flex-wrap: wrap

    padding: 6.4rem 0 5rem
    &__item
      $this: &
      width: 33%
      min-height: 11rem
      margin: 0 0 7rem 0
      padding: 0

      position: relative
      display: flex
      align-items: flex-start

      transition: all .5s
      @media only screen and (max-width: $small)
        width: 50%
        min-height: auto
      @media only screen and (max-width: $micro)
        width: 100%
      &-content
        order: 1
      &-icon
        width: 2rem
        margin: .4rem .75rem 0
        height: auto
        transition: all .5s
      &-chapter
        @include h3
        padding-bottom: 2.4rem
      &-text
        padding: 0 2.4rem 2.4rem 0
      &:nth-last-child(-n+3)
        margin: 0
        @media only screen and (max-width: $small)
          margin: 0 0 7rem 0
  .b-buttons
    display: flex
    justify-content: center
    /deep/
     button
       min-width: 25rem
</style>
