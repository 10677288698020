<template>
  <the-block>
    <div class="b-what-they">
      <div class="b-chapter">
        {{ chapter }}
      </div>
      <div class="b-list">
        <div class="b-list__item" v-for="(item, index) in list" :key="index">
          <div class="b-list__item-icon" :style="{ 'background-image' : item.icon ? `url(${require(`../../assets/images/${item.icon}`)})` : 'none'}"></div>
          <div class="b-list__item-content">
            <div class="b-list__item-chapter" v-html="item.chapter"></div>
            <div class="b-list__item-text" v-html="item.text"></div>
          </div>
        </div>
      </div>
    </div>
  </the-block>
</template>

<script>
const LIST = [
  {
    chapter: 'Управление доходом',
    text: 'Создайте витрину своих уроков и курсов, позвольте ученикам заранее оплачивать ваши услуги. ',
    icon: 'what1.svg'
  },
  {
    chapter: 'Довольные ученики',
    text: 'Все инструменты для эффективного и интерактивного обучения собраны на одной платформе. Возможен доступ с компьютера, планшета и смартфона.',
    icon: 'what2.svg'
  },
  {
    chapter: 'Творческая свобода',
    text: 'Пробуйте новые подходы в обучении, создавайте авторские методики. Экспериментируйте с уроками, начните создавать курсы и тесты. ',
    icon: 'what3.svg'
  },
  {
    chapter: 'Управление временем',
    text: 'Укажите удобное время для работы в своем расписании. Ваши ученики смогут самостоятельно бронировать, переносить, отменять уроки. ',
    icon: 'what4.svg'
  }
]
export default {
  name: 'TheWhatThey',
  props: {
    chapter: {
      default: 'Что получают учителя?',
      type: String
    },
    list: {
      default: () => LIST,
      type: Array
    }
  }
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .b-what-they
    margin: 4.9rem 10rem
    display: flex
    flex-direction: column
    @media only screen and (max-width: $mini)
      margin: 4.9rem
    @media only screen and (max-width: $micro)
      margin: 4.9rem 2rem
  .b-chapter
    @include h1
    font-weight: 400
    color: $gray900
    margin: 0 0 5.8rem
  .b-list
    display: flex
    justify-content: center
    flex-wrap: wrap
    &__item
      width: 50%
      color: $gray900
      display: flex
      margin: 0 0 7.2rem
      @media only screen and (max-width: $micro)
        width: 100%
        flex-direction: column
      &-icon
        width: 7.2rem
        height: 7.2rem
        background-repeat: no-repeat
        background-size: contain
      &-content
        width: 100%
        padding: 0 0 0 4rem
        @media only screen and (max-width: $micro)
          padding: 0
      &-chapter
        @include h3
      &-text
        margin: .8rem 0 0
</style>
