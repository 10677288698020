<template>
  <the-block>
    <div class="b-simply-available">
      <div class="b1">
        <div class="b-chapter">Просто и удобно</div>
        <div class="b-text">Визуальная, понятная и интуитивная платформа позволит любому студенту быстро включиться в
          процесс обучения.
        </div>
      </div>
      <div class="b-pic" />
    </div>
  </the-block>
</template>

<script>
export default {
  name: 'TheSimplyAvailable'
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .b-simply-available
    width: 100%
    height: 28.7rem
    max-width: $large
    margin: 5rem auto
    padding: 3rem 12.2rem
    position: relative

    background-color: $white
    border-radius: .8rem
    @media only screen and (max-width: $mini)
      padding: 6rem
      height: auto
    @media only screen and (max-width: $micro)
      padding: 3rem 4rem 0 1rem
      height: auto
      margin: 0 auto 8rem
  .b-pic
    position: absolute
    top: -5rem
    right: 2%
    z-index: 1
    width: 51.1rem
    height: 38.9rem
    background-image: url(../../assets/images/violet-letters.png)
    background-repeat: no-repeat
    background-position: center
    background-size: contain
    @media only screen and (max-width: $small)
      right: -20%
    @media only screen and (max-width: $mini)
      right: -40%
    @media only screen and (max-width: $micro)
      width: 30rem
      height: 20rem
      top: -5rem
      right: -62%
  .b1
    width: 55rem
    height: 100%

    display: flex
    flex-direction: column
    justify-content: center
    @media only screen and (max-width: $mini)
      width: 100%
  .b-chapter
    @include h1
    margin-bottom: 3rem
  .b-text
    @include p
</style>
