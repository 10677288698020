<template>
  <the-block>
    <div class="b-end">
      <div class="b-logo"></div>
      <div class="b-chapter" v-html="chapter"></div>
      <div class="b-row">
        <div class="b-col">
          <the-button color="base" size="middle" @click="$router.push('/signup')">
            Начать
          </the-button>
        </div>
        <div class="b-col">
          <div class="b-text">
            Бесплатно
          </div>
          <div class="b-text">
            Гарантия получения оплат
          </div>
          <div class="b-text">
            Лёгкость и простота использования
          </div>
        </div>
      </div>
    </div>
  </the-block>
</template>

<script>
export default {
  name: 'TheEnd',
  props: {
    chapter: {
      default: 'Эффективное обучение <br>начинается с <b>4smart.pro!</b>',
      type: String
    }
  }
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .b-end
    width: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    position: relative
    padding: 10rem 0 0
  .b-logo
    position: absolute
    left: 50%
    top: -7rem
    width: 14rem
    height: 14rem
    margin: 0 0 0 -7rem
    background-image: url(../../assets/images/logo.png)
    background-repeat: no-repeat
    background-position: center
    background-size: contain
  .b-chapter
    text-align: center
    @include h1
    font-weight: 400
  .b-row
    display: flex
    justify-content: center
    align-items: center

    margin: 6rem 0
    @media only screen and (max-width: $small)
      flex-direction: column
  .b-col
    margin: 0 0 3rem
  .b-text
    background-image: url(../../assets/images/check-c.svg)
    background-repeat: no-repeat
    background-position: left center
    padding: 0 0 0 3.4rem
    margin: .8rem 0 .8rem 5.2rem
</style>
