<template>
  <div class="b-overlay">
    <div class="b-content">
      <span class="b-close" @click="close()" />
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheOverlay',

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .b-overlay
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
    z-index: 999

    max-width: 100vw
    max-height: 100vh

    display: flex
    justify-content: center
    align-items: center

    background-color: rgba(0, 0, 0, .8)
    transition: all .5s
  .b-content
    width: 100%
    height: 100%
    max-width: 70vw
    max-height: 80vh
    position: relative
    display: flex
    justify-content: center
    align-items: flex-start
    @media only screen and (min-width: $xlarge)
      max-width: 144rem
  .b-close
    position: absolute
    top: -4.2rem
    right: -4.2rem
    width: 2.8rem
    height: 2.8rem
    background-image: url(../../assets/images/x.svg)
    background-repeat: no-repeat
    background-size: cover
    cursor: pointer
    transition: all .5s
    &:hover
      transform: rotate(90deg)
</style>
