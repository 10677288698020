<template>
  <div id="main" class="b-for-tutors">
    <the-container>
      <the-main2
        chapter="Повышайте <br>качество уроков <br>и уровень дохода"
        text="Проводите увлекательные уроки, создавайте востребованные курсы и зарабатывайте больше!"
        background="bg-b1-2.png"
      />
    </the-container>
    <the-container>
      <the-advantages />
    </the-container>
    <the-container>
      <the-what-they />
    </the-container>
    <the-container>
      <the-features :list="features" />
    </the-container>
    <the-container>
      <the-solutions :isToggle="true" />
    </the-container>
    <the-container>
      <the-intro
        class="b-intro-tutors"
        chapter="Мы хотим, чтобы вы процветали, поэтому дарим вам гид <br>“8 простых шагов к созданию увлекательного обучающего курса”. <br>Скачивайте и зарабатывайте на своих знаниях! "
        background="bg-intro2.png"
        btnText="Скачать"
      />
    </the-container>
    <the-container>
      <the-end />
    </the-container>
    <the-container>
      <the-footer>
        <the-footer-menu />
      </the-footer>
    </the-container>
  </div>
</template>

<script>

const FEATURES = [
  {
    icon: 'features1.svg',
    chapter: 'Управление <br>доходом',
    text: 'Продавайте пакеты из нескольких занятий или курсы студентам. Обеспечьте себе гарантии получения средств, даже при поздней отмене.',
    url: '#',
    btnText: 'Начать'
  },
  {
    icon: 'features3.svg',
    chapter: 'Довольные ученики',
    text: 'Используйте все возможности виртуального класса для создания увлекательных и эффективных уроков.',
    url: '#',
    btnText: 'Начать'
  },
  {
    icon: 'features5.svg',
    chapter: 'Творческая свобода',
    text: 'Вы можете обучать так, как привыкли, а можете пробовать новое - создание курсов, тестов, шаблонов, и выходить на новый уровень доходов и мастерства. ',
    url: '#',
    btnText: 'Начать'
  },
  {
    icon: 'features2.svg',
    chapter: 'Управление <br>временем',
    text: 'Укажите удобное время работы в расписании и позвольте студентам самостоятельно бронировать, отменять или переносить занятия на другое время.',
    url: '#',
    btnText: 'Начать'
  }
]

export default {
  name: 'ForTutors',

  data () {
    return {
      features: FEATURES
    }
  }
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .b-for-tutors
    overflow: hidden
  .b-intro-tutors
    /deep/
      .b-intro
        .b-chapter
          @include h3
          @media only screen and (max-width: $micro)
            font-size: 1.6rem
</style>
