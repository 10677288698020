<template>
  <the-block>
    <div class="b-intro" :style="{ 'background-image' : background ? `url(${require(`../../assets/images/${background}`)})` : 'none'}">
      <div class="b-intro__content">
        <div class="b-chapter" v-html="chapter" />
        <template v-if="text !== ''">
          <div class="b-text" v-html="text" />
        </template>
        <div class="b-button">
          <the-button color="base" size="middle" @click="$router.push('/signup')">
            {{ btnText }}
          </the-button>
        </div>
      </div>
    </div>
  </the-block>
</template>

<script>
export default {
  name: 'TheIntro',

  props: {
    chapter: {
      default: 'Используйте инновационные <br>инструменты обучения бесплатно',
      type: String
    },
    text: String,
    btnText: {
      default: 'Начать',
      type: String
    },
    background: {
      default: 'bg-intro.png',
      type: String
    }
  }
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .b-intro
    width: 100%
    position: relative
    margin: 5rem 0 10rem
    min-height: 60rem

    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    background-repeat: no-repeat
    background-position: center
    background-size: contain
    @media only screen and (max-width: $mini)
      min-height: 30rem
      margin: 0 0 10rem
    @media only screen and (max-width: $micro)
      background-image: url(../../assets/images/bg-b1.png) !important
    &__content
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
    &__bg
      width: 100%
      & img
        width: 100%
  .b-chapter
    text-align: center
    @include h1
    font-size: 3.6rem
    padding: 3rem 10rem
    @media only screen and (max-width: $small)
      font-size: 3rem
    @media only screen and (max-width: $mini)
      font-size: 2.4rem
      padding: 10rem 10rem 3rem
    @media only screen and (max-width: $micro)
      font-size: 1.6rem
      padding: 3rem
  .b-text
    text-transform: uppercase
    @include p
  .b-button
    margin: 1rem 0 0
</style>
