// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/bg-tariff.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/5-per.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".b-tariff[data-v-31968a40]{width:100%;height:62.8rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:50%}@media only screen and (max-width:768px){.b-tariff[data-v-31968a40]{background-position:50% -50%;height:auto;padding-bottom:10rem}}.b-row[data-v-31968a40]{width:100%;height:62.8rem;display:flex;justify-content:center;align-items:center}@media only screen and (max-width:768px){.b-row[data-v-31968a40]{flex-direction:column;height:auto}}.b-chapter[data-v-31968a40]{font-family:Montserrat Alternates,Helvetica,Arial,sans-serif;font-size:4.8rem;line-height:1.2;font-weight:700;white-space:nowrap;text-align:right;padding:0 4rem 8rem 0}@media only screen and (max-width:980px){.b-chapter[data-v-31968a40]{font-size:3rem}}@media only screen and (max-width:768px){.b-chapter[data-v-31968a40]{font-size:2.4rem;text-align:center;padding:0}}.b-percent[data-v-31968a40]{font-size:1.4rem;text-align:center;min-width:28.5rem;min-height:25.3rem;height:62.8rem;padding:36rem 0 0;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-repeat:no-repeat;background-size:contain;background-position:center 35%}@media only screen and (max-width:768px){.b-percent[data-v-31968a40]{height:auto;padding:26rem 0 0}}.b-text[data-v-31968a40]{padding:0 0 4rem 4rem;line-height:1.8}@media only screen and (max-width:768px){.b-text[data-v-31968a40]{padding:2rem 0 0}}.b-h3[data-v-31968a40]{font-size:2.4rem;font-family:Montserrat Alternates,Helvetica,Arial,sans-serif;font-weight:700;line-height:1.2;padding:0 0 1rem 3rem}@media only screen and (max-width:980px){.b-h3[data-v-31968a40]{font-size:2rem}}@media only screen and (max-width:768px){.b-h3[data-v-31968a40]{font-size:2rem;padding:0 0 2rem;text-align:center}}", ""]);
// Exports
module.exports = exports;
