// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/violet-letters.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".b-simply-available[data-v-73af6d0f]{width:100%;height:28.7rem;max-width:1440px;margin:5rem auto;padding:3rem 12.2rem;position:relative;background-color:#fff;border-radius:.8rem}@media only screen and (max-width:768px){.b-simply-available[data-v-73af6d0f]{padding:6rem;height:auto}}@media only screen and (max-width:700px){.b-simply-available[data-v-73af6d0f]{padding:3rem 4rem 0 1rem;height:auto;margin:0 auto 8rem}}.b-pic[data-v-73af6d0f]{position:absolute;top:-5rem;right:2%;z-index:1;width:51.1rem;height:38.9rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:50%;background-size:contain}@media only screen and (max-width:980px){.b-pic[data-v-73af6d0f]{right:-20%}}@media only screen and (max-width:768px){.b-pic[data-v-73af6d0f]{right:-40%}}@media only screen and (max-width:700px){.b-pic[data-v-73af6d0f]{width:30rem;height:20rem;top:-5rem;right:-62%}}.b1[data-v-73af6d0f]{width:55rem;height:100%;display:flex;flex-direction:column;justify-content:center}@media only screen and (max-width:768px){.b1[data-v-73af6d0f]{width:100%}}.b-chapter[data-v-73af6d0f]{font-family:Montserrat Alternates,Helvetica,Arial,sans-serif;font-size:4.8rem;line-height:1.2;font-weight:700;margin-bottom:3rem}@media only screen and (max-width:980px){.b-chapter[data-v-73af6d0f]{font-size:3rem}}@media only screen and (max-width:768px){.b-chapter[data-v-73af6d0f]{font-size:2.4rem}}.b-text[data-v-73af6d0f]{font-size:2rem;font-weight:400;line-height:1.2}@media only screen and (max-width:980px){.b-text[data-v-73af6d0f]{font-size:1.6rem}}", ""]);
// Exports
module.exports = exports;
