<template>
  <the-block>
    <div class="b-features" id="features">
      <div class="b-list">
        <div class="b-list__item" v-for="(item, index) in list" :key="index">
          <div class="b-list__item-pic">
            <img :src="require(`../../assets/images/${item.icon}`)" :alt="item.chapter" />
          </div>
          <div class="b-list__item-content">
            <div class="b-list__item-chapter" v-html="item.chapter"></div>
            <div class="b-list__item-text">{{ item.text }}</div>
            <a :href="item.url" class="b-list__item-link">
              <the-button color="base-transparent" size="small" @click="$router.push('/signup')">
                {{ item.btnText }}
              </the-button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </the-block>
</template>

<script>
const LIST = [
  {
    icon: 'features1.svg',
    chapter: 'Приём платежей',
    text: 'Продавайте пакеты из нескольких занятий или курсы студентам. Обеспечьте себе гарантии получения средств, даже при поздней отмене.',
    url: '#',
    btnText: 'Начать'
  },
  {
    icon: 'features2.svg',
    chapter: 'Расписание и <br>календарь',
    text: 'Укажите удобное время работы в расписании и позвольте студентам самостоятельно бронировать, отменять или переносить занятия на другое время.',
    url: '#',
    btnText: 'Начать'
  },
  {
    icon: 'features3.svg',
    chapter: 'Виртуальный класс',
    text: 'Больше не нужно переключать программы во время онлайн-занятия. Используйте все инструменты виртуального класса в одном окне.',
    url: '#',
    btnText: 'Начать'
  },
  {
    icon: 'features4.svg',
    chapter: 'Надёжный <br>видеосервис',
    text: 'Мы используем интеграцию с Zoom для обеспечения высокого качества видеосвязи.',
    url: '#',
    btnText: 'Начать'
  },
  {
    icon: 'features5.svg',
    chapter: 'Конструктор курсов',
    text: 'Создавайте курсы за считанные минуты. С помощью простого перетаскивания добавляйте материалы и задания из готовых шаблонов.',
    url: '#',
    btnText: 'Начать'
  },
  {
    icon: 'features6.svg',
    chapter: 'Система <br>оповещений',
    text: 'Мы сами напомним вашим студентам о начале занятий, оплатах, повышении цен и других важных событиях в процессе обучения.',
    url: '#',
    btnText: 'Начать'
  }
]

export default {
  name: 'TheFeatures',
  props: {
    list: {
      default: () => LIST,
      type: Array
    }
  }
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .b-features
    width: 100%
  .b-list
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-end

    padding: 0 0 5rem
    @media only screen and (max-width: $micro)
      padding: 0 0 5rem 2rem
    &__item
      $this: &
      width: 100%
      min-height: 28rem
      padding: 0 4rem
      margin: 0 0 12rem

      position: relative
      display: flex
      justify-content: flex-start
      align-items: center

      transition: all .5s
      @media only screen and (max-width: $small)
        margin: 0 0 4rem
        height: auto
      @media only screen and (max-width: $micro)
        flex-direction: column
        padding: 0
      &-content
        width: 50%
        order: 1
        padding: 0 7.8rem 0 0
        @media only screen and (max-width: $small)
          padding: 0 2.8rem 0 0
        @media only screen and (max-width: $micro)
          order: 2
          width: 100%
      &-pic
        width: 50%
        height: auto
        transition: all .5s
        order: 2
        text-align: left
        & img
          width: 100%
        @media only screen and (max-width: $micro)
          order: 1
          width: 100%
          & img
            margin-bottom: 4rem
      &-chapter
        @include h1
        padding-bottom: 2.4rem
      &-text
        padding-bottom: 2.4rem
      &-link
        @include a
        color: $violet
      &:nth-child(2n)
        justify-content: flex-end
        #{$this}-content
          order: 2
          @media only screen and (max-width: $micro)
            order: 2
        #{$this}-pic
          order: 1
          text-align: right
          padding: 0 7.8rem 0 0
          @media only screen and (max-width: $micro)
            order: 1
      &:nth-child(2n + 1)
        #{$this}-content
          order: 1
          @media only screen and (max-width: $micro)
            order: 2
        #{$this}-pic
          order: 2
          @media only screen and (max-width: $micro)
            order: 1
      &:last-child
        margin-bottom: 0
</style>
