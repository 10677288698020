<template>
    <div class="l-404">
      <div class="b-404">
        <div class="b-img" />
        <div class="b-text">
          Нет такой страницы :(
        </div>
        <a href="/" class="b-link">
          Вернуться на главную
        </a>
      </div>
    </div>
</template>

<script>
export default {
  name: '404'
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .l-404
    width: 100%
    height: 100vh
    display: flex
    justify-content: center
    align-items: center
  .b-404
    width: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
  .b-img
    width: 100%
    max-width: 52.2rem
    min-height: 21.5rem
    background-image: url(../../assets/images/404.png)
    background-repeat: no-repeat
    background-size: contain
    background-position: center
  .b-text
    @include h3
    text-align: center
    margin: 5rem 0
</style>
