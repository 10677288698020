import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueRouter from 'vue-router'
import router from './router'
import { sync } from 'vuex-router-sync'
import axios from 'axios/index'
import { getCookie } from './utils'
import Vuebar from 'vuebar'
import './components/_globals'
import Vuelidate from 'vuelidate'
import VueYandexMetrika from 'vue-yandex-metrika'

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(Vuebar)
Vue.use(Vuelidate)

sync(store, router)

// request interceptor
const createSetAuthInterceptor = () => config => {
  if (getCookie('token') !== null) {
    config.headers.Authorization = `Bearer ${getCookie('token')}`
  }
  return config
}

const setAuthCb = createSetAuthInterceptor(getCookie('token'))
axios.interceptors.request.use(setAuthCb)

// response interceptor
let refreshTokenPromise

const createUpdateAuthInterceptor = (store, http) => async error => {
  let method = error.response.config.url.split('/').pop()
  let status = error.response.status

  if ((status === 500 || status === 401) && method === 'refresh') {
    store.dispatch('User/clearAuth')
  }
  if (error.response.data.error.code !== 401) {
    return Promise.reject(error)
  }

  if (!refreshTokenPromise) {
    refreshTokenPromise = store.dispatch('User/refreshToken')
  }

  await refreshTokenPromise

  refreshTokenPromise = null

  return http(error.config)
}

const updateAuthCb = createUpdateAuthInterceptor(store, axios)
axios.interceptors.response.use(null, updateAuthCb)

Vue.use(VueYandexMetrika, {
  id: process.env.VUE_APP_METRIKA,
  router: router,
  env: process.env.NODE_ENV,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true
  }
})

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
