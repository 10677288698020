<template>
  <div class="b-swipe">
    <div class="b-chapter">Почему нас выбирают?</div>
    <div class="b-swipe-wrap">
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide v-for="(item, index) in list" :key="index">
          <the-founder
            :avatar="item.avatar"
            :cloudChapter="item.cloudChapter"
            :cloudText="item.cloudText"
            :name="item.name"
            :text="item.text"
          />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import 'swiper/components/pagination/pagination.min.css'
import SwiperCore, { Pagination } from 'swiper/core'
SwiperCore.use([Pagination])

const LIST = [
  {
    avatar: 'avatar1.png',
    cloudChapter: 'Нет неловкости',
    cloudText: 'Для меня ваша платформа - спасение! Всегда было неудобно называть стоимость своих услуг, напоминать об оплатах или  говорить о повышении цен на занятия. Сейчас платформа это делает сама. Я меняю только настройки. Ура!',
    name: 'Григорий Ли',
    text: 'Репетитор английского и корейского языков'
  },
  {
    avatar: 'avatar2.png',
    cloudChapter: 'Все в одном месте',
    cloudText: 'Огромное спасибо  разработчикам за платформу! Тут действительно собрано все в одном месте. Особенно радует возможность переходить на Wordwalls и Youtube не выходя из программы.',
    name: 'Наталья Иванова',
    text: 'Репетитор английского языка'
  },
  {
    avatar: 'avatar3.png',
    cloudChapter: 'Интуитивный дизайн',
    cloudText: 'Я не так давно с  4smart.pro. Понравилась простота и доступность создания урока. Ученикам не приходится объяснять, как и что нужно нажать, где что расположено.  Все  очень понятно.',
    name: 'Александр Гусев',
    text: 'Репетитор по математике и физике'
  }
]

export default {
  name: 'TheSwiper',
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      list: LIST,
      swiperOption: {
        slidesPerView: 3,
        lazy: true,
        spaceBetween: 0,
        initialSlide: 1,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
            spaceBetween: 0
          },
          1024: {
            slidesPerView: 1,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
            spaceBetween: 0
          },
          1440: {
            slidesPerView: 3,
            slidesOffsetBefore: 75,
            slidesOffsetAfter: 75,
            spaceBetween: 0
          }
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .b-swipe
    position: relative
    width: 100%
    padding-bottom: 10rem
    background: rgba(213, 250, 252, 0.3)
    &-wrap
      width: 160%
      position: relative
      left: -30%
      @media (min-width: $xlarge)
        width: 100%
        left: auto
  .b-chapter
    @include h1
    font-weight: 400
    text-align: center
    padding: 5.6rem 2rem 4rem
  .swiper-container
    padding: 0 0 3rem
  .swiper-slide
    padding-bottom: 3rem
  .swiper-pagination
    /deep/
      .swiper-pagination-bullet
        background: #B7E1FF !important
        opacity: 1 !important
        margin: 0 8px
        &-active
          background: #53B7FF !important
</style>
