<template>
  <the-block>
    <div class="b-what-they">
      <div class="b-chapter">
        {{ chapter }}
      </div>
      <div class="b-list">
        <div class="b-list__item" v-for="(item, index) in list" :key="index">
          <div class="b-list__item-icon" :style="{ 'background-image' : item.icon ? `url(${require(`../../assets/images/${item.icon}`)})` : 'none'}"></div>
          <div class="b-list__item-content">
            <div class="b-list__item-chapter" v-html="item.chapter"></div>
            <div class="b-list__item-text" v-html="item.text"></div>
          </div>
        </div>
      </div>
    </div>
  </the-block>
</template>

<script>
const LIST = [
  {
    chapter: 'Клиентский <br>опыт',
    text: 'Клиентам важны не дипломы эксперта, но достижение ими результата и первоклассный сервис. Создавайте свои программы на платформе, а мы обеспечим превосходный клиентский опыт.',
    icon: 'what5.svg'
  },
  {
    chapter: 'Создание <br>ценности ',
    text: 'Клиентам важно показать разницу между БЫЛО и СТАЛО, чтобы они могли ценить эксперта и его труд. Документируйте результаты и отслеживайте прогресс на 4smart.pro.',
    icon: 'what6.svg'
  },
  {
    chapter: 'Продажа сессий <br>дорого',
    text: 'Клиенты готовы платить дорого за результат и первоклассный сервис. Это и есть превосходный клиентский опыт. С нашей платформой — это просто.',
    icon: 'what7.svg'
  }
]
export default {
  name: 'TheWhatThey',
  props: {
    chapter: {
      default: 'Почему нужен 4smart.pro?',
      type: String
    },
    list: {
      default: () => LIST,
      type: Array
    }
  }
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .b-what-they
    margin: 4.9rem 0
    display: flex
    flex-direction: column
    @media only screen and (max-width: $mini)
      margin: 4.9rem
    @media only screen and (max-width: $micro)
      margin: 4.9rem 2rem
  .b-chapter
    @include h2
    font-weight: 400
    color: $gray900
    margin: 0 0 5.8rem
  .b-list
    display: flex
    margin: 0 4rem
    @media only screen and (max-width: $micro)
      flex-direction: column
      margin: 0
    &__item
      color: $gray900
      display: flex
      margin: 0
      padding: 0 10rem 0 0
      flex-direction: column
      @media only screen and (max-width: $micro)
        width: 100%
        padding: 0
        margin: 0 0 7.2rem
      &-icon
        width: 7.2rem
        height: 7.2rem
        background-repeat: no-repeat
        background-size: contain
      &-content
        width: 100%
        padding: 0
        @media only screen and (max-width: $micro)
          padding: 0
      &-chapter
        @include h3
      &-text
        margin: 3rem 0 0
</style>
