<template>
  <the-block class="b1 b1-bg" id="whom">
    <div class="b-content">
      <div class="b-chapter">
        {{ chapter }}
      </div>
      <div class="b-list">
        <div class="b-list__item" v-for="(item, index) in list" :key="index">
          <div class="b-list__item-icon">
            <img :src="require(`../../assets/images/${item.icon}`)" :alt="item.chapter"/>
          </div>
          <div class="b-list__item-chapter" v-html="item.chapter"></div>
          <div class="b-list__item-text">{{ item.text }}</div>
          <span class="b-list__item-link" @click="$router.push(item.url)">{{ item.btnText }}</span>
        </div>
      </div>
    </div>
  </the-block>
</template>

<script>
const LIST = [
  {
    icon: 'for-whom1.svg',
    chapter: 'Учителей </br>и репетиторов',
    text: 'Используйте платформу, на которой есть все, что нужно, для подготовки и проведения увлекательных занятий.',
    url: 'for-tutors',
    btnText: 'Узнать подробнее'
  },
  {
    icon: 'for-whom2.svg',
    chapter: 'Владельцев </br>онлайн-школ',
    text: 'Организуйте эффективный учебный процесс своей школы без вложений и ограничений по пользователям.',
    url: 'for-schools',
    btnText: 'Узнать подробнее'
  },
  {
    icon: 'for-whom3.svg',
    chapter: 'Экспертов </br>и консультантов',
    text: 'Продавайте авторские курсы и пакеты консультаций, обеспечьте превосходный клиентский опыт.',
    url: 'for-experts',
    btnText: 'Узнать подробнее'
  }
]

export default {
  name: 'TheForWhom',

  props: {
    chapter: {
      default: 'Мы создали 4smart.pro для',
      type: String
    },
    list: {
      default: () => LIST,
      type: Array
    }
  }
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .b-chapter
    @include h2
  .b-list
    display: flex
    justify-content: flex-start
    align-items: flex-end

    padding: 7% 0 5%
    @media only screen and (max-width: $micro)
      align-items: flex-start
      flex-wrap: wrap
    &__item
      $this: &
      width: 33%
      height: 41.1rem
      padding: 16rem 4rem 0
      margin: 0 5rem 0 0

      position: relative

      transition: all .5s
      @media only screen and (max-width: $small)
        width: 100%
        padding: 16rem 1rem 0
        margin: 0 0 5rem 0
      @media only screen and (max-width: $micro)
        height: auto
        padding: 4rem
      &:hover
        background-color: $white
        box-shadow: 0 16px 24px rgba(109, 145, 200, 0.15)
        border-radius: 8px
      &-icon
        position: absolute
        bottom: 30rem
        left: 4rem
        width: 11rem
        height: auto
        transition: all .5s
        @media only screen and (max-width: $micro)
          position: relative
          bottom: auto
          left: auto
          margin-bottom: 4rem
        #{$this}:hover &
          bottom: 31rem
          left: 8rem
          transform: scale(1.7)
          @media only screen and (max-width: $micro)
            position: relative
            bottom: auto
            left: auto
            margin-bottom: 4rem
            transform: scale(1)
      &-chapter
        @include h3
        padding-bottom: 3rem
      &-text
        padding-bottom: 4rem
        min-height: 12rem
        @media only screen and (max-width: $micro)
          min-height: auto
      &-link
        @include a
        color: $violet
        cursor: pointer
</style>
