import Vue from 'vue'
import Vuex from 'vuex'
import User from './User'

Vue.use(Vuex)

const state = {
  sections: []
}

const getters = {

}

const actions = {
  changeSectionData ({ state, commit }, { data, index }) {
    let sections = state.sections.slice()
    sections[index] = data
    commit('setSections', sections)
  }
}

const mutations = {
  setSections (state, value) {
    state.sections = value
  }
}

const modules = {
  User
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules
})
