import Main from './components/pages/Main'
import TheSignUp from './components/pages/TheSignUp'
import TheLead from './components/pages/TheLead'
import ForTutors from './components/pages/ForTutors'
import ForSchools from './components/pages/ForSchools'
import ForExperts from './components/pages/ForExperts'
import NotFound from './components/pages/404'

const routes = [
  {
    path: '/',
    component: Main,
    meta: {
      title: '4Smart - Всё, что нужно для онлайн-обучения, в одном месте'
    }
  },
  {
    path: '/signup',
    component: TheSignUp,
    meta: {
      title: '4Smart - Получи доступ к новому уровню онлайн-обучения'
    }
  },
  {
    path: '/lead',
    component: TheLead,
    meta: {
      title: '4Smart - 8 простых шагов к созданию увлекательного обучающего курса'
    }
  },
  {
    path: '/for-tutors',
    component: ForTutors,
    meta: {
      title: '4Smart - Повышайте качество уроков и уровень дохода'
    }
  },
  {
    path: '/for-schools',
    component: ForSchools,
    meta: {
      title: '4Smart - Создайте свою онлайн-школу'
    }
  },
  {
    path: '/for-experts',
    component: ForExperts,
    meta: {
      title: '4Smart - Начните успешный онлайн-бизнес'
    }
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
    meta: {
      title: '4Smart - 404'
    }
  },
  {
    path: '*',
    redirect: '/404'
  }
]

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
/* function lazyLoadView (AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: import('@components/base/BaseLoading'),
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: import('@components/base/BaseTimeout'),
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 70,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 15000
  })

  return Promise.resolve({
    functional: true,
    render (h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    }
  })
} */

export default routes
