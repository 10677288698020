<template>
  <the-block>
    <div class="b-content">
      <div class="b-chapter">
        {{ chapter }}
      </div>
      <div class="row">
        <div class="col1">
          <span class="chapter">Это бесплатно</span>
          <span class="text">Вам не нужно заранее оплачивать подписку <br>за пользование платформой.</span>
        </div>
        <div class="col2">
          <span class="text">Коммисия 5% только за оплаченные занятия или курсы. </span>
        </div>
      </div>
      <div class="row row2">
        <div class="col1 col1-1">
          <span class="chapter">Всё в одном месте</span>
          <span class="text">Мы собрали все, что нужно для проведения <br>увлекательных занятий в одном месте</span>
        </div>
      </div>
      <div class="b-list">
        <div class="b-list__item" v-for="(item, index) in list" :key="index">
          <div class="b-list__item-icon">
            <img :src="require(`../../assets/images/${item.icon}`)" :alt="item.chapter" />
          </div>
          <div class="b-list__item-chapter" v-html="item.chapter"></div>
        </div>
      </div>
    </div>
  </the-block>
</template>

<script>
const LIST = [
  {
    icon: 'why1.svg',
    chapter: 'качественный </br>видеосервис Zoom'
  },
  {
    icon: 'why2.svg',
    chapter: 'удобный обмен </br>материалами'
  },
  {
    icon: 'why3.svg',
    chapter: 'интерактивная </br>доска'
  },
  {
    icon: 'why4.svg',
    chapter: 'конструктор курсов </br>и тестов'
  },
  {
    icon: 'why5.svg',
    chapter: 'возможность перехода </br>на ссылки сторонних </br>сервисов в окне занятия'
  }
]

export default {
  name: 'TheWhy',

  props: {
    chapter: {
      default: 'Почему 4smart?',
      type: String
    },
    list: {
      default: () => LIST,
      type: Array
    }
  }
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .b-chapter
    @include h2
  .b-list
    display: flex
    justify-content: flex-start
    align-items: flex-end

    padding: 6rem 0 5rem
    @media only screen and (max-width: $micro)
      flex-wrap: wrap
    &__item
      $this: &
      width: 20rem
      margin: 0 3rem 0 0
      padding: 6rem 0 0
      height: 10rem

      position: relative

      transition: all .5s
      @media only screen and (max-width: $micro)
        width: 38%
        height: auto
      &-icon
        position: absolute
        top: 0
        left: 0
        width: 6rem
        height: 4rem
        transition: all .5s

        display: flex
        justify-content: center
        align-items: center
        #{$this}:last-child &
          left: -1rem
      &-chapter
        padding: 0 0 3rem 1rem
        @media only screen and (max-width: $micro)
          min-height: 15rem
  .row
    $this: &
    display: flex
    justify-content: flex-start
    align-items: center

    margin-bottom: 9rem
    height: 25rem
    @media only screen and (max-width: $micro)
      flex-direction: column
      align-items: flex-start
      margin: 3rem 0 9rem
    &.row2
      height: auto
      margin-bottom: 0
    .col1
      width: 45%
      height: 100%
      padding: 0 15rem 0 0
      display: flex
      flex-direction: column
      justify-content: center
      align-items: flex-start
      @media only screen and (max-width: $small)
        padding: 0 5rem 0 0
        width: 40%
      &.col1-1
        width: 100%
      .chapter
        @include h1
        padding-bottom: 1.6rem
      @media only screen and (max-width: $micro)
        width: 100%
    .col2
      width: 55%
      height: 100%
      background-image: url(../../assets/images/free-coins.svg)
      background-repeat: no-repeat
      background-position: center
      background-size: contain

      display: flex
      justify-content: center
      align-items: center
      @media only screen and (max-width: $small)
        width: 60%
      @media only screen and (max-width: $micro)
        width: 100%
        margin: 3rem 0 0
      .text
        width: 20rem
        height: 6rem
        text-align: center

        position: relative
        left: -6rem
        @media only screen and (max-width: $small)
          left: -5rem
</style>
