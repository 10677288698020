<template>
  <div class="l-footer">
    <the-block>
      <div class="b-footer">
        <slot />
        <div class="row">
          <div class="b-copy">
            © 2021 4smart.pro. Все права защищены &nbsp;
          </div>
          <div class="b-socials">
            <a class="b-soc" v-for="(item, index) in socials" :key="index" :href="item.url" target="_blank">
              <img :src="require(`../../assets/images/${item.icon}`)" :alt="item.title" />
            </a>
          </div>
        </div>
        <div class="row">
          <div class="b-links">
            <template v-for="link in links">
              <a :key="link.name" :href="link.url" class="b-link" target="_blank">{{ link.text }}</a>
            </template>
          </div>
        </div>
      </div>
    </the-block>
  </div>
</template>

<script>
const SOCIALS = [
  {
    title: 'Вконтакте',
    icon: 'vk.svg',
    url: 'https://vk.com/4smart_group'
  },
  {
    title: 'Instagram',
    icon: 'insta.svg',
    url: 'https://www.instagram.com/4smart.pro/'
  },
  {
    title: 'Facebook',
    icon: 'fb.svg',
    url: 'https://www.facebook.com/4smartfb/'
  },
  {
    title: 'Telegram',
    icon: 'tg.svg',
    url: 'https://t.me/news_4smart'
  },
  {
    title: 'YouTube',
    icon: 'yt.svg',
    url: 'https://www.youtube.com/channel/UCozdq54qDiaoeTrVb4A_nXw/about'
  }
]

const LINKS = [
  {
    name: 'agreement',
    text: 'Пользовательское соглашение',
    url: 'https://cdn.ptah.pro/prod/609e1524b54bda0001a6a191/c32fe0d2-f92d-4a9c-b2d9-c313c86f7e69.pdf'
  },
  {
    name: 'policy',
    text: 'Политика конфиденциальности',
    url: 'https://cdn.ptah.pro/prod/609e1524b54bda0001a6a191/4de4677b-fbe1-4a2a-88ae-bcab8af14039.pdf'
  },
  {
    name: 'personal',
    text: 'Обработка персональных данных',
    url: 'https://cdn.ptah.pro/prod/609e1524b54bda0001a6a191/1b6da076-fdaf-4587-b516-f884c547b522.pdf'
  }
]

export default {
  name: 'TheFooter',
  data () {
    return {
      socials: SOCIALS,
      links: LINKS
    }
  }
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .l-footer
    background-color: $violet
  .b-footer
    padding: 2.5rem 0
  .row
    display: flex
    justify-content: space-between
    align-items: center
  .b-copy
    font-size: 1.2rem
    color: $white
  .b-links
    font-size: 1.2rem
    display: flex
    flex-wrap: wrap
    padding: 1rem 0 0
    a
      color: $white
      margin: 0 1rem 0 0
  .b-socials
    display: flex
  .b-soc
    width: 3.2rem
    height: 3.2rem
    margin: 0 .8rem
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer

    background-color: rgba($white, .1)
    transition: all .3s
    border-radius: 50%
    &:hover
      background-color: rgba($white, .3)
    & img
      width: 1.8rem
      height: auto
</style>
