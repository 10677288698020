<template>
  <div class="b-block">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TheBlock'
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .b-block
    width: 100%
    max-width: $large
    margin: 0 auto
    padding: 3rem 6rem
    @media only screen and (max-width: $mini)
      padding: 2rem 4rem
</style>
