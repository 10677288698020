<template>
  <div class="b-base-text-field"
       :class="{
    'b-base-text-field_error': hasError,
    'b-base-text-field_focus': hasFocus || innerValue,
    'b-base-text-field_filled': innerValue.length > 0
    }">

    <masked-input
      class="b-base-text-field__input"
      v-if="type === 'tel'"
      ref="inputText"
      v-bind="$attrs"
      v-model="innerValue"
      @input="$emit('input', innerValue)"
      @keydown.enter="$emit('onEnter', innerValue)"
      @keyup.stop=""
      @focus.native="$emit('focus', $event), hasFocus = true"
      @blur.native="$emit('blur', $event), hasFocus = false"
      mask="\+\7 (111) 111-11-11" />

    <input class="b-base-text-field__input"
           v-if="type !== 'tel'"
           ref="inputText"
           :type="type"
           v-bind="$attrs"
           v-model="innerValue"
           @input="$emit('input', innerValue)"
           @keydown.enter="$emit('onEnter', innerValue)"
           @keyup.stop=""
           @focus="$emit('focus', $event), hasFocus = true"
           @blur="$emit('blur', $event), hasFocus = false"
           :maxlength="maxLength"
    />

    <label
      class="b-base-text-field__label"
      v-if="label || $slots.label"
      :hasError="hasError"
      :hasFocus="hasFocus"
    >
      {{label}}
      <slot name="label" />
    </label>

    <span class="b-base-text-field__bar"></span>

    <base-error-text v-if="hasError">
      {{errorText}}
      <slot name="error"></slot>
    </base-error-text>
  </div>
</template>

<script>
import MaskedInput from 'vue-masked-input'
export default {
  components: {
    MaskedInput
  },

  model: {
    prop: 'value',
    event: 'input'
  },

  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 255
    }
  },

  watch: {
    value (value) {
      this.innerValue = value
    }
  },

  data () {
    return {
      innerValue: this.value,
      hasFocus: false
    }
  }
}
</script>

<style lang="sass" scoped>
  @import '../../../assets/sass/colors'
  @import '../../../assets/sass/_variables.sass'
  .b-base-text-field
    $this: &
    line-height: 2rem
    width: 100%
    color: $gray300
    position: relative
    margin: 1rem 0
    @media only screen and (max-width: $micro)
      margin: 1.5rem 0 1rem
    &_error
      #{$this}__label
        color: $error-red
      #{$this}__bar:before,  #{$this}__bar:after
        background: $error-red

    &_focus
      #{$this}__label
        transform: translateY(-1.9rem) scale(0.8)
        color: $base

      #{$this}__bar:before,  #{$this}__bar:after
        width: 50%

    &__label
      transform-origin: 0 100%
      color: $gray300
      position: absolute
      top: -.5rem
      font-size: 1.6rem
      transition: transform .2s ease-out, color .2s ease-out, -webkit-transform .2s ease-out
      pointer-events: none
      @media only screen and (max-width: $micro)
        line-height: 1
        top: -1rem
    &__bar
      position: relative
      display: block

      &:before, &:after
        content: ''
        height: 2px
        width: 0
        bottom: 0
        position: absolute
        background: $base
        transition: .2s ease-out all

      &:before
        left: 50%

      &:after
        right: 50%

    &__input
      display: block
      font-size: 1.6rem
      border: none
      border-bottom: 1px solid $gray300
      width: 100%
      height: 3rem
      outline: 0
      -webkit-user-select: auto

      &:disabled
        border-color: transparent
        color: #888888
        cursor: pointer

</style>
