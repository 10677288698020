<template>
  <div class="l-top-menu">
    <div class="b-hamburger"
      :class="{ '_hide' : isShowMobileMenu }"
      @click="show"
    />
    <div class="b-top-menu"
      :class="{ '_show' : isShowMobileMenu }"
    >
      <span class="b-link" v-for="link in links" :key="link.name" @click="scrollTo(link.name)">
        {{ link.text }}
      </span>
      <the-button class="b-btn" :color="'base'" @click="$router.push('/signup')">
        Войти
      </the-button>
      <span class="b-close"
        :class="{ '_hide' : !isShowMobileMenu }"
        @click="hide"
      />
    </div>
  </div>
</template>

<script>
import VueScrollTo from 'vue-scrollto'

const LINKS = [
  {
    name: 'features',
    text: 'Возможности',
    url: ''
  },
  {
    name: 'whom',
    text: 'Решения',
    url: ''
  },
  {
    name: 'tariffs',
    text: 'Тарифы',
    url: ''
  }
]

export default {
  name: 'TheTopMenu',

  data () {
    return {
      links: LINKS,
      isShowMobileMenu: false
    }
  },

  methods: {
    show () {
      this.isShowMobileMenu = true
    },

    hide () {
      this.isShowMobileMenu = false
    },

    scrollTo (id) {
      this.hide()

      let options = {
        container: 'body',
        duration: 500,
        easing: 'ease',
        offset: -80,
        force: true,
        cancelable: true,
        onStart: false,
        onDone: false,
        onCancel: false,
        x: false,
        y: true
      }
      VueScrollTo.scrollTo(`#${id}`, 500, options)
    }
  }
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .l-top-menu
    position: relative
    max-width: $large
  .b-top-menu
    max-width: $large
    transition: all .5s
    @media only screen and (max-width: $micro)
      margin: 0
      text-align: center
      position: fixed
      top: 0
      right: 0
      // bottom: 0
      left: 0
      z-index: 999
      height: 0

      display: flex
      flex-direction: column
      align-items: center
      justify-content: center

      background-color: $white

      opacity: 0
      &._show
        display: flex
        opacity: 1
        bottom: 0
        height: auto
  .b-link
    font-family: 'Rubik'
    font-size: 1.6rem
    text-transform: uppercase
    margin: 0 1.6rem
    cursor: pointer
    transition: all .5s
    @media only screen and (max-width: $micro)
      margin: 3.2rem 1.6rem
    &:hover
        color: $violet
  .b-btn
    @media only screen and (max-width: $micro)
      margin: 6rem 0 0
  .b-close
    position: absolute
    top: 4.2rem
    right: 4.2rem
    width: 2.8rem
    height: 2.8rem
    background-image: url(../../assets/images/x.svg)
    background-repeat: no-repeat
    background-size: cover
    cursor: pointer
    transition: all .5s
    &._hide
      display: none
    @media only screen and (min-width: $micro)
      display: none
  .b-hamburger
    display: block
    width: 2.8rem
    height: 2.8rem
    background-image: url(../../assets/images/hamburger.svg)
    background-repeat: no-repeat
    background-size: contain
    cursor: pointer
    margin: 1rem 0 0
    @media only screen and (min-width: $micro)
      display: none
    &._hide
      display: none
</style>
