<template>
  <div class="b-founder">
    <div class="b-cloud">
      <template v-if="cloudChapter !== ''">
        <div class="b-cloud__chapter" v-html="cloudChapter"></div>
      </template>
      <div v-html="cloudText"></div>
      <div class="b-cloud__quote">“</div>
    </div>
    <div class="b-owner">
      <div class="b-avatar">
        <img :src="require(`../../assets/images/${avatar}`)" :alt="name" />
      </div>
      <div class="b-content">
        <div class="b-name" v-html="name"></div>
        <div class="b-text" v-html="text"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheFounder',
  props: {
    avatar: {
      default: 'olga.png',
      type: String
    },

    cloudChapter: {
      default: '',
      type: String
    },

    cloudText: {
      default: 'Мы помогаем учителям и экспертам  зарабатывать больше, <br>сосредоточившись на главном - создании уникального процесса <br>обучения, интересно и без рутины.',
      type: String
    },

    text: {
      default: 'CEO, 4smart',
      type: String
    },

    name: {
      default: 'Ольга Грищенкова',
      type: String
    }
  }
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .b-founder
    width: 75.4rem
    position: relative
    left: calc(50% - 75.4rem/2)
    @media only screen and (max-width: $mini)
      width: 60rem
      left: calc(50% - 60rem/2)
    @media only screen and (max-width: $micro)
      width: 32rem
      left: calc(50% - 32rem/2)
  .b-cloud
    width: 75.4rem
    //height: 23.2rem
    padding: 4rem 4rem 4rem 8rem
    background-color: $white
    border-radius: .8rem
    display: flex
    flex-direction: column
    box-shadow: 0 16px 24px rgba(109, 145, 200, 0.15)
    position: relative
    @include p
    @media only screen and (max-width: $mini)
      width: 100%
      & > div
        width: 100%
    &__chapter
      @include h3
      margin: 0 0 1.6rem
    &__quote
      position: absolute
      top: 3.2rem
      left: 2.8rem
      z-index: 3
      @include h1
      color: $violet
    &:before
      content: ""
      position: absolute
      width: 2rem
      height: 2rem
      bottom: -1rem
      left: 50%
      margin-left: -1rem
      background: $white
      transform: rotate(225deg)
      z-index: 2
    &:after
      content: ""
      position: absolute
      width: 2rem
      height: 2rem
      bottom: -1rem
      left: 50%
      margin-left: -1rem
      background: $white
      transform: rotate(225deg)
      box-shadow: 0 0 2rem 0 rgba($black, 0.35)
      z-index: -1
  .b-owner
    width: 25.7rem
    min-height: 5.6rem
    margin: 2.4rem auto

    display: flex
    justify-content: flex-start
    align-items: center
  .b-avatar
    background-repeat: no-repeat
    background-position: center
    background-size: contain
    width: 5.6rem
    height: 5.6rem
    & > img
      width: 5.6rem
      height: 5.6rem
  .b-content
    padding: .65rem 0 .65rem 1.6rem
  .b-name
    @include p
</style>
