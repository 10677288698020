// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/404.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".l-404[data-v-669d8c90]{height:100vh}.b-404[data-v-669d8c90],.l-404[data-v-669d8c90]{width:100%;display:flex;justify-content:center;align-items:center}.b-404[data-v-669d8c90]{flex-direction:column}.b-img[data-v-669d8c90]{width:100%;max-width:52.2rem;min-height:21.5rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:contain;background-position:50%}.b-text[data-v-669d8c90]{font-size:2.4rem;font-family:Montserrat Alternates,Helvetica,Arial,sans-serif;font-weight:700;line-height:1.2;text-align:center;margin:5rem 0}@media only screen and (max-width:980px){.b-text[data-v-669d8c90]{font-size:2rem}}@media only screen and (max-width:768px){.b-text[data-v-669d8c90]{font-size:2rem}}", ""]);
// Exports
module.exports = exports;
