<template>
  <div class="b-header">
    <router-link to="/" class="b-logo">
      4Smart
    </router-link>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TheHeader'
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'
  .b-header
    width: 100%
    max-width: $large

    display: flex
    justify-content: space-between
    align-items: center
    flex-direction: row
  .b-logo
    display: block
    width: 14.9rem
    height: 2.525rem
    background-image: url(../../assets/logo.svg)
    background-repeat: no-repeat
    background-size: cover
    text-indent: -9999px
    @media only screen and (max-width: $micro)
      margin: 2rem 0
</style>
