<template>
  <div class="b-signup">
    <the-container class="b-signup__header">
      <the-header :show-menu="false"></the-header>
    </the-container>

    <div class="b-signup__container">
      <div class="b-signup__logo"></div>

      <h2>Регистрация</h2>

      <form action="" class="b-signup__form" @submit.prevent="submit">
        <base-form-row>
          <base-radio-check
            :list="userRoleList"
            v-model="$v.userRole.$model"
            :hasError="$v.userRole.$error"
            variant="grid"
            :errorText="errorTexts.userRole"
          />
        </base-form-row>

        <base-form-row>
          <base-text-field
            v-model="$v.lastName.$model"
            :hasError="$v.lastName.$error"
            :errorText="errorTexts.lastName">
            <template slot="label">
              Фамилия
            </template>
          </base-text-field>
        </base-form-row>

        <base-form-row>
          <base-text-field
            v-model="$v.firstName.$model"
            :hasError="$v.firstName.$error"
            :errorText="errorTexts.firstName">
            <template slot="label">
              Имя
            </template>
          </base-text-field>
        </base-form-row>

        <base-form-row>
          <base-text-field v-model="phone" type="tel">
            <template slot="label">
              Телефон
            </template>
          </base-text-field>
        </base-form-row>

        <base-form-row>
          <base-text-field
            v-model="$v.email.$model"
            :hasError="errors.email || $v.email.$error"
            :errorText="errorTexts.email">
            <template slot="label">
              Электронная почта
            </template>
          </base-text-field>
        </base-form-row>

        <base-form-row>
          <base-text-field
            v-model="$v.password.$model"
            type="password"
            :hasError="errors.password || $v.password.$error"
            :errorText="errorTexts.password">
            <template slot="label">
              Пароль (8 символов, латинские буквы и цифры)
            </template>
          </base-text-field>
        </base-form-row>

        <base-form-row align="center">
          <the-button
            type="submit"
            color="base"
            size="middle"
            :disabled="loading">
            Зарегистрироваться
          </the-button>
        </base-form-row>

        <base-form-row align="center" class="agreement">
          Нажимая на кнопку, я принимаю <br>
          <a href="https://cdn.ptah.pro/prod/609e1524b54bda0001a6a191/c32fe0d2-f92d-4a9c-b2d9-c313c86f7e69.pdf"
             target="_blank">Пользовательское соглашение</a> и
          <a href="https://cdn.ptah.pro/prod/609e1524b54bda0001a6a191/4de4677b-fbe1-4a2a-88ae-bcab8af14039.pdf"
             target="_blank">Политику конфиденциальности</a>
        </base-form-row>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required, minLength, email } from 'vuelidate/lib/validators'
import TheContainer from '../base/TheContainer'
import TheHeader from '../base/TheHeader'
import { getCookie } from '../../utils'

export default {
  name: 'TheSignUp',
  components: { TheHeader, TheContainer },

  data () {
    return {
      errors: {
        userRole: false,
        email: false,
        lastName: false,
        firstName: false,
        password: false
      },
      errorTexts: {
        userRole: 'Пожалуйста, укажите свой вид деятельности',
        email: 'Пожалуйста, укажите ваш Email',
        lastName: 'Пожалуйста, укажите фамилию',
        firstName: 'Пожалуйста, укажите имя',
        password: 'Пароль должен содержать не менее 8 символов и содержать латинские буквы и цифры'
      },
      errorsArr: {
        'signup_name_is_required': {
          name: 'name',
          text: 'Пожалуйста, укажите имя'
        },
        'signup_invalid_email': {
          name: 'email',
          text: 'Пожалуйста, укажите ваш Email'
        },
        'signup_email_cannot_be_used': {
          name: 'email',
          text: 'Пожалуйста, укажите другой Email'
        },
        'signup_weak_password': {
          name: 'password',
          text: 'Пароль должен содержать не менее 8 символов и содержать латинские буквы и цифры'
        }
      },
      loading: false,
      userRoleList: [
        {
          text: 'Учитель',
          value: 'teacher'
        },
        {
          text: 'Эксперт/консультант',
          value: 'expert'
        },

        {
          text: 'Онлайн-школа',
          value: 'school'
        },
        {
          text: 'Студент',
          value: 'student'
        }
      ],
      userRole: '',
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      password: ''
    }
  },

  validations: {
    userRole: {
      required
    },
    email: {
      required,
      email
    },
    firstName: {
      required
    },
    lastName: {
      required
    },
    password: {
      required,
      valid: (value) => {
        const containsCase = /[a-zA-Z]/.test(value)
        const containsNumber = /[0-9]/.test(value)
        return containsCase && containsNumber
      },
      minLength: minLength(8)
    }
  },

  methods: {
    ...mapActions('User', ['signUp']),

    submit () {
      console.log('submit')
      this.loading = true
      this.$v.$touch()
      this.resetErrors()

      if (!this.$v.$invalid) {
        this.signUp({
          email: this.email,
          name: `${this.firstName} ${this.lastName}`,
          password: this.password,
          phone: this.phone,
          busyness: this.userRole,
          gauid: getCookie('_ga'),
          ymuid: getCookie('_ym_uid')
        })
          .then(() => {
            try {
              window.gtag('event', 'registration_complete', { 'event_category': 'SITE' })
            } catch (e) {
              console.log('registration_complete_gtag')
            }

            try {
              this.$metrika.reachGoal('reg')
            } catch (e) {
              console.log('registration_complete_metrika')
            }

            this.$router.push('/lead')
          })
          .catch(e => {
            const mess = e.response.data.error.message
            const name = this.errorsArr[mess].name
            const text = this.errorsArr[mess].text

            this.errorTexts[name] = text
            this.errors[name] = true
          })
          .finally(() => { this.loading = false })
      } else {
        this.loading = false
      }
    },

    resetErrors () {
      Object.keys(this.errors).forEach(key => {
        this.errors[key] = false
      })

      Object.keys(this.errorTexts).forEach(key => {
        this.errorTexts[key] = ''
      })
    }
  }
}
</script>

<style lang="sass" scoped>
  @import '../../assets/sass/_variables'
  @import '../../assets/sass/_colors'

  .b-signup
    text-align: center

    &__header
      padding: 4.4rem 5.9rem

    &__container
      width: 38rem
      margin: -8rem auto 0
      text-align: center
      @media only screen and (max-width: $micro)
        padding: 0 2rem

    &__logo
      width: 15.2rem
      height: 15.4rem
      background: url('../../assets/images/logo_x2.png') no-repeat
      background-size: cover
      margin: 0 auto 7.4rem
      @media only screen and (max-width: $micro)
        margin: 5rem auto

    .agreement
      font-size: 1.4rem
      line-height: 1.7rem
      margin: 0 -3rem

    h2
      @include h2
      text-transform: none
</style>
