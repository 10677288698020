// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/x.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/hamburger.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".l-top-menu[data-v-6bbc612e]{position:relative;max-width:1440px}.b-top-menu[data-v-6bbc612e]{max-width:1440px;transition:all .5s}@media only screen and (max-width:700px){.b-top-menu[data-v-6bbc612e]{margin:0;text-align:center;position:fixed;top:0;right:0;left:0;z-index:999;height:0;display:flex;flex-direction:column;align-items:center;justify-content:center;background-color:#fff;opacity:0}.b-top-menu._show[data-v-6bbc612e]{display:flex;opacity:1;bottom:0;height:auto}}.b-link[data-v-6bbc612e]{font-family:Rubik;font-size:1.6rem;text-transform:uppercase;margin:0 1.6rem;cursor:pointer;transition:all .5s}@media only screen and (max-width:700px){.b-link[data-v-6bbc612e]{margin:3.2rem 1.6rem}}.b-link[data-v-6bbc612e]:hover{color:#7754ff}@media only screen and (max-width:700px){.b-btn[data-v-6bbc612e]{margin:6rem 0 0}}.b-close[data-v-6bbc612e]{position:absolute;top:4.2rem;right:4.2rem;width:2.8rem;height:2.8rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:cover;cursor:pointer;transition:all .5s}.b-close._hide[data-v-6bbc612e]{display:none}@media only screen and (min-width:700px){.b-close[data-v-6bbc612e]{display:none}}.b-hamburger[data-v-6bbc612e]{display:block;width:2.8rem;height:2.8rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-repeat:no-repeat;background-size:contain;cursor:pointer;margin:1rem 0 0}@media only screen and (min-width:700px){.b-hamburger[data-v-6bbc612e]{display:none}}.b-hamburger._hide[data-v-6bbc612e]{display:none}", ""]);
// Exports
module.exports = exports;
